import styled from 'styled-components';

import { useGeneralContext } from '../../../context/GeneralContextProvider';

import { BsBrush } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../../constants';
import { INPAINTING_PATH } from '../../../pathNames';
import { desktopMediaQuery } from '../../../styleHelpers';
import { FiTrash } from 'react-icons/fi';
import useShareImage from '../../../hooks/images/useShareImage';
import { AiOutlinePlusSquare, AiOutlineSave } from 'react-icons/ai';
import SaveButtonInGenerate from './SaveButtonInGenerate';
import { useHandleDownloadImage } from '../../../hooks/images/useHandleDownloadImage';
import { fadeIn } from '../../../components/ImageStuff/animations';
import { StyledButton } from '../../../components/common/StyledButton';
import { MdAutorenew } from 'react-icons/md';
import { useImageGenerationSettingsContext } from '../../../context/ImageGenerationSettingsProvider';

interface P {
  handleRemovePhoto: () => void;
  handleClickCreateVariations: () => void;
}

const ButtonsBelowImage = ({
  handleRemovePhoto,
  handleClickCreateVariations,
}: P) => {
  const { activeImage } = useGeneralContext();
  const { uploadedImageUrl } = useImageGenerationSettingsContext();
  const navigate = useNavigate();

  const shareImage = useShareImage();
  const handlePostToFeed = () => {
    shareImage(activeImage);
  };

  const handleClickInpaint = async () => {
    if (!activeImage.prompt) {
      alert('Please remix an image first');
      return;
    }
    navigate(INPAINTING_PATH);
  };

  const isWideScreen = window.innerWidth > 450;

  const handleDownloadImage = useHandleDownloadImage();
  const handleClickDownload = () => {
    handleDownloadImage(activeImage);
  };

  const isAlreadyCreatingVariationsOfThisImage =
    activeImage.imageUrl === uploadedImageUrl;

  return (
    <Container>
      <TopButtonContainer>
        {!isAlreadyCreatingVariationsOfThisImage && (
          <CreateVariationButton onClick={handleClickCreateVariations}>
            <MdAutorenew color={PRIMARY_COLOR} size={20} />
            Make Variations & Keep Face
          </CreateVariationButton>
        )}
      </TopButtonContainer>
      <BottomButtonsContainer>
        <RemoveButtonContainer onClick={handleRemovePhoto}>
          <FiTrash size={20} />
          {isWideScreen && 'Delete'}
        </RemoveButtonContainer>

        <DownloadContainer onClick={handleClickDownload}>
          <DownloadIconInGenerate size={22} onClick={handleClickDownload} />
          {isWideScreen && 'DL'}
        </DownloadContainer>

        <PostToFeedContainer onClick={handlePostToFeed}>
          <AiOutlinePlusSquare size={22} /> Post
        </PostToFeedContainer>

        <InpaintingButtonContainer onClick={handleClickInpaint}>
          <BsBrush size={20} /> Edit
        </InpaintingButtonContainer>

        <SaveButtonInGenerate image={activeImage} showSaveText={isWideScreen} />
      </BottomButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding-left: 16px;
  gap: 16px;
  margin-bottom: 24px;
  margin-top: 2px;

  // hack so when going up looks better
  animation: ${fadeIn} 3s ease-in-out;
`;

const TopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin-bottom: 4px;
`;

const CreateVariationButton = styled(StyledButton)`
  font-size: 14px;
  @media (min-width: 568px) {
    font-size: 16px;
  }
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 14px;
  padding-right: 14px;

  //center everything
  display: flex;
  align-items: center;
  gap: 6px;
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-left: 2vw;
  margin-right: 2vw;
`;

const sharedStyle = `
  font-size: 14px;
  @media (min-width: 568px) {
    font-size: 18px;
  }

  transition: color 0.2s;
  cursor: pointer;
  ${desktopMediaQuery} {
    &:hover {
      color: ${PRIMARY_COLOR};
    }
  }
  align-items: center;
  display: flex;
  gap: 6px;
`;

const RemoveButtonContainer = styled.div`
  ${sharedStyle}
  margin-bottom: 2px;
`;

const DownloadIconInGenerate = styled(AiOutlineSave)``;

const DownloadContainer = styled.div`
  ${sharedStyle}
`;

const PostToFeedContainer = styled.div`
  ${sharedStyle}

  @media (max-width: 568px) {
    margin-right: -4px;
  }
`;

const InpaintingButtonContainer = styled.div`
  ${sharedStyle}

  color: ${PRIMARY_TEXT_COLOR};
  @media (max-width: 568px) {
    margin-right: -4px;
  }
`;

export default ButtonsBelowImage;
