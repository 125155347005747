import styled from 'styled-components';
import { InputContainer } from '../../../components/common/InputContainer';
import LabelWithTooltip from '../../../components/common/LabelWithTooltip';
import StylishInput from '../../../components/common/StyledInput';
import { FlexBoxRow } from '../../../components/common/FlexBoxRow';
import { ClearInputIcon } from '../../../components/common/ClearInputIcon';
import { SECONDARY_TEXT_COLOR } from '../../../constants';
import { StyleInterface } from '../../../types';
import { useEffect } from 'react';
import { HiddenPromptText } from '../SharedComponentsInImgGenPage';
import { useImageGenerationSettingsContext } from '../../../context/ImageGenerationSettingsProvider';
import EditButtonForStyles from '../StylesSection/EditButtonForStyles';

interface P {
  negativePrompt: string;
  handleSetNegativePrompt: (negativePrompt: string) => void;
  selectedStyle: StyleInterface;
}

const TOOLTIP_TEXT =
  "This is the 'negative prompt'. AI avoids rendering the concepts written here. Very important for good results. Add ((paranthesis around)) to emphasize words; this means the AI will be 'reminded' to really avoid them";

const NegativePromptBox = ({
  negativePrompt,
  handleSetNegativePrompt,
  selectedStyle,
}: P) => {
  const { showHiddenStyleSettings } = useImageGenerationSettingsContext();
  const calculateCurrentPromptLength = () => {
    const promptLength = negativePrompt.length;
    const styleNegativePromptLength = selectedStyle?.negativePrompt.length ?? 0;
    return promptLength + styleNegativePromptLength;
  };

  const promptLength = calculateCurrentPromptLength();

  useEffect(() => {
    if (promptLength > 750) {
      // if promptLength goes above 750
      // cut off latest character for prompt
      handleSetNegativePrompt(negativePrompt.slice(0, -1));
    }
  }, [promptLength]);

  const postNegativePrompt = selectedStyle?.negativePrompt ?? '';
  return (
    <BiggerContainer>
      <LabelContainer>
        <LabelWithTooltip
          labelText="❌ Negative prompt"
          tooltipText={TOOLTIP_TEXT}
        />
        <FlexBoxRow>
          {promptLength} / 750
          <ClearInputIcon onClick={() => handleSetNegativePrompt('')} />
        </FlexBoxRow>
      </LabelContainer>
      <StyledInputContainer>
        <StylishInput
          value={negativePrompt}
          setValue={handleSetNegativePrompt}
          placeholder="Example: deformed paws"
          negPrompBox
        />
        {showHiddenStyleSettings && (
          <HiddenPromptText postPrompt>
            {postNegativePrompt}
            <EditButtonForStyles />
          </HiddenPromptText>
        )}
      </StyledInputContainer>
    </BiggerContainer>
  );
};

const BiggerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  width: 98%;

  margin-bottom: 32px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-left: 2px;

  color: ${SECONDARY_TEXT_COLOR};
`;

const StyledInputContainer = styled(InputContainer)`
  margin-top: 6px;
  width: 100%;
`;

export default NegativePromptBox;
