import styled from "styled-components";
import { FakeImage } from "../../types";
import { SmoothlyLoadingImage } from "../../components/common/SmoothlyLoadingImage";
import { useState } from "react";
import { imageHoverAnimationInFeed } from "../../styleHelpers";

interface ImageTemplateNewProps {
  image: FakeImage;
}

const FeedImage = ({ image }: ImageTemplateNewProps) => {
  const [loaded, setLoaded] = useState(false);
  const imageUrl = image?.imageUrl;

  if (!imageUrl) return null;

  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <Image
        src={
          imageUrl ||
          "https://excellance.com/wp-content/plugins/photonic/include/images/placeholder-Sm.png"
        }
        alt="Loading..."
        loading="lazy"
        loaded={loaded}
        onLoad={() => setLoaded(true)}
      />
    </Container>
  );
};

export default FeedImage;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 240px;
  max-height: 300px;
`;

const Image = styled(SmoothlyLoadingImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;

  ${imageHoverAnimationInFeed};
`;
