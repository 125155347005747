import { createContext, useContext, useState } from "react";
import {
  FakeImage,
  FolderInterface,
  GenericSetState,
  SharedImage,
} from "../types";

interface IImagesContext {
  savedImages: FakeImage[];
  setSavedImages: GenericSetState<FakeImage[]>;

  hasFetchedSavedImages: boolean; // helper used to know if we should fetch saved images
  setHasFetchedSavedImages: GenericSetState<boolean>;

  sharedImages: SharedImage[];
  setSharedImages: GenericSetState<SharedImage[]>;

  latestFeedImages: SharedImage[];
  setLatestFeedImages: GenericSetState<SharedImage[]>;

  //datetime when last fetched
  fetchedLastTime: number;
  setFetchedLastTime: GenericSetState<number>;

  lastCategory: string;
  setLastCategory: GenericSetState<string>;

  lastSortBy: string;
  setLastSortBy: GenericSetState<string>;

  latestOrBestLastTime: "latest" | "best" | undefined;
  setLatestOrBestLastTime: GenericSetState<"latest" | "best" | undefined>;

  templates: SharedImage[];
  setTemplates: GenericSetState<SharedImage[]>;

  failedTrackIds: string[];
  setFailedTrackIds: GenericSetState<string[]>;

  lastSharedDate: Date | null;
  setLastSharedDate: GenericSetState<Date | null>;

  editedImages: FakeImage[];
  setEditedImages: GenericSetState<FakeImage[]>;
}

const MS_TO_MINUS = 200;

const defaultState = {
  savedImages: [],
  setSavedImages: undefined as unknown as GenericSetState<FakeImage[]>,

  hasFetchedSavedImages: false,
  setHasFetchedSavedImages: undefined as unknown as GenericSetState<boolean>,

  sharedImages: [],
  setSharedImages: undefined as unknown as GenericSetState<SharedImage[]>,
  latestFeedImages: [],
  setLatestFeedImages: undefined as unknown as GenericSetState<SharedImage[]>,

  fetchedLastTime: Date.now() - MS_TO_MINUS,
  setFetchedLastTime: undefined as unknown as GenericSetState<number>,

  lastCategory: "",
  setLastCategory: undefined as unknown as GenericSetState<string>,

  lastSortBy: "",
  setLastSortBy: undefined as unknown as GenericSetState<string>,

  latestOrBestLastTime: undefined,
  setLatestOrBestLastTime: undefined as unknown as GenericSetState<
    "latest" | "best" | undefined
  >,

  templates: [],
  setTemplates: undefined as unknown as GenericSetState<SharedImage[]>,

  failedTrackIds: [],
  setFailedTrackIds: undefined as unknown as GenericSetState<string[]>,

  lastSharedDate: null,
  setLastSharedDate: undefined as unknown as GenericSetState<Date | null>,

  editedImages: [],
  setEditedImages: undefined as unknown as GenericSetState<FakeImage[]>,

  folders: [],
  setFolders: undefined as unknown as GenericSetState<FolderInterface[]>,
};

const ImagesContext = createContext<IImagesContext>(defaultState);

export const useImagesContext = () => useContext<IImagesContext>(ImagesContext);

// @ts-ignore children does actually exist, todo figure types?
const LoggedInUserContextProvider = ({ children }) => {
  const [savedImages, setSavedImages] = useState<FakeImage[]>([]);
  const [hasFetchedSavedImages, setHasFetchedSavedImages] =
    useState<boolean>(false);

  const [sharedImages, setSharedImages] = useState<SharedImage[]>([]);

  const [latestFeedImages, setLatestFeedImages] = useState<SharedImage[]>([]);

  const [fetchedLastTime, setFetchedLastTime] = useState<number>(
    Date.now() - MS_TO_MINUS
  );

  const [lastSharedDate, setLastSharedDate] = useState<Date | null>(null);

  const [lastCategory, setLastCategory] = useState<string>("");
  const [lastSortBy, setLastSortBy] = useState<string>("");

  const [latestOrBestLastTime, setLatestOrBestLastTime] = useState<
    "latest" | "best" | undefined
  >(undefined);

  const [templates, setTemplates] = useState<SharedImage[]>([]);

  const [failedTrackIds, setFailedTrackIds] = useState<string[]>([]);

  const [editedImages, setEditedImages] = useState<FakeImage[]>([]);

  return (
    <ImagesContext.Provider
      value={{
        savedImages,
        setSavedImages,

        hasFetchedSavedImages,
        setHasFetchedSavedImages,

        sharedImages,
        setSharedImages,
        latestFeedImages,
        setLatestFeedImages,
        fetchedLastTime,
        setFetchedLastTime,

        lastCategory,
        setLastCategory,
        lastSortBy,
        setLastSortBy,

        latestOrBestLastTime,
        setLatestOrBestLastTime,

        templates,
        setTemplates,

        failedTrackIds,
        setFailedTrackIds,

        lastSharedDate,
        setLastSharedDate,

        editedImages,
        setEditedImages,
      }}
    >
      {children}
    </ImagesContext.Provider>
  );
};

export default LoggedInUserContextProvider;
