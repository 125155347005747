import { createContext, useContext, useState } from "react";
import { GenericSetState, LoggedInUser } from "../types";

interface ILoggedInUserContext {
  loggedInUser: LoggedInUser | null;
  setLoggedInUser: GenericSetState<LoggedInUser | null>;
}

const defaultState = {
  loggedInUser: null,
  setLoggedInUser: undefined as unknown as GenericSetState<LoggedInUser | null>,
};

const LoggedInUserContext = createContext<ILoggedInUserContext>(defaultState);

export const useLoggedInUserContext = () => useContext(LoggedInUserContext);

// @ts-ignore children does actually exist, todo figure types?
const LoggedInUserContextProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | null>(null);

  return (
    <LoggedInUserContext.Provider
      value={{
        loggedInUser,
        setLoggedInUser,
      }}
    >
      {children}
    </LoggedInUserContext.Provider>
  );
};

export default LoggedInUserContextProvider;
