import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { StyledButton } from '../../components/common/StyledButton';
import { BORDER_COLOR } from '../../constants';
import { FaArrowLeft, FaShoppingCart } from 'react-icons/fa';
import { desktopMediaQuery } from '../../styleHelpers';

const Redirecting: React.FC = () => {
  const location = useLocation();
  const [didSomethingGoWrong, setDidSomethingGoWrong] = React.useState(false);

  const query = new URLSearchParams(location.search);
  const paymentType = query.get('paymentType') as string;
  const username = query.get('username') as string;
  //const processor = query.get('processor') as string;

  if (!paymentType || !username) {
    setDidSomethingGoWrong(true);
    alert(
      'Something went wrong... Go back to previous page. Contact hello@picarus.ai if you need help.',
    );
  }

  const returnToPreviousPage = () => {
    window.history.back();
  };

  const goToGumroad = () => {
    const URL_BASE_PART = 'https://picarusdev.gumroad.com/l';
    const urlCodeForSmallCredits = 'mopte';
    const urlForSmallCredits = `${URL_BASE_PART}/${urlCodeForSmallCredits}?Username=${username}`;

    const urlCodeForMediumCredits = 'pxaqj';
    const urlForMediumCredits = `${URL_BASE_PART}/${urlCodeForMediumCredits}?Username=${username}`;

    const urlCodeForLargeCredits = 'uvreul';
    const urlForLargeCredits = `${URL_BASE_PART}/${urlCodeForLargeCredits}?Username=${username}`;

    if (paymentType === 'small-credits') {
      window.open(urlForSmallCredits, '_blank');
    } else if (paymentType === 'medium-credits') {
      window.open(urlForMediumCredits, '_blank');
    } else if (paymentType === 'large-credits') {
      window.open(urlForLargeCredits, '_blank');
    } else {
      alert(
        'Something went wrong... Go back to previous page. Contact hello@picarus.ai if you need help.',
      );
    }
  };

  if (didSomethingGoWrong) {
    return (
      <Container>
        <Text>
          Something went wrong... Go back to previous page. Contact
          hello@picarus.ai if you need help.
        </Text>
        <GoBackButton onClick={returnToPreviousPage}>
          <FaArrowLeft /> Go back
        </GoBackButton>
      </Container>
    );
  }

  return (
    <Container>
      <Text>
        Your payment will be processed through Gumroad. You will receive credits
        to this username: <b>{username}</b>
      </Text>
      <StyledButton onClick={goToGumroad}>
        <FaShoppingCart />
        Pay Now
      </StyledButton>

      <EmptySpace />
      <Subtitle>
        After completing the payment, your credits will be added to your account
        within a minute. If you don't see the credits, please refresh the page.
        For any issues, contact us at hello@picarus.ai.
      </Subtitle>
      <GoBackButton onClick={returnToPreviousPage}>
        <FaArrowLeft /> Go back
      </GoBackButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;

const EmptySpace = styled.div`
  height: 36px;
`;

const Text = styled.p`
  width: 80%;
  max-width: 800px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 20px;
  ${desktopMediaQuery} {
    font-size: 24px;
  }
`;

const Subtitle = styled(Text)`
  color: grey;
`;

const GoBackButton = styled(StyledButton)`
  border: 1px solid ${BORDER_COLOR};
`;

export default Redirecting;
