import styled from 'styled-components';
import DrawingCanvas from './DrawingCanvas';
import { BsArrowLeft } from 'react-icons/bs';
import GeneratedInpaintsContainer from './GeneratedInpaintsContainer';
import { useImagesContext } from '../../context/ImageContextProvider';
import { MAX_WIDTH } from '../../constants';
import { useEffect, useRef, useState } from 'react';
import useHandleUpdatingTitle from '../../hooks/useHandleUpdatingTitle';
import { desktopMediaQuery } from '../../styleHelpers';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { waitSeconds } from '../../components/helpers';
import { moveToTop } from '../../hooks/helpers';
import useHandleReferenceImageUploading from '../AdvancedPage/AdvancedOptions/useHandleReferenceImageUploading';

const InpaintingPage = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  const { setActiveImage } = useGeneralContext();
  const { editedImages, setEditedImages } = useImagesContext();
  const { size, setSize } = useImageGenerationSettingsContext();
  const { loggedInUser } = useLoggedInUserContext();

  useEffect(() => {
    setEditedImages([]);
    moveToTop();
  }, [setEditedImages]);
  useHandleUpdatingTitle();

  const setUploadedImageUrl = (url: string) => {
    setActiveImage((prev) => {
      return {
        ...prev,
        imageUrl: url,
        width: Number(size.width),
        height: Number(size.height),
      };
    });
  };

  const { isUploading, handleImageUpload } = useHandleReferenceImageUploading(
    setUploadedImageUrl,
    setSize,
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const showUpload = loggedInUser?.username === 'alpo';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState(0);
  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    handleImageUpload(event);
    await waitSeconds(5);
    setState(1);
  };

  return (
    <Container>
      <BackButton onClick={handleGoBack}>BACK</BackButton>

      <TitleText>✏️ Inpainting</TitleText>
      {editedImages.length === 0 ? (
        <>
          {showUpload && (
            <>
              <HiddenInput
                type="file"
                accept=".png,.jpg,.jpeg,.webp"
                onChange={onChange}
                disabled={isUploading}
                ref={fileInputRef}
              />
            </>
          )}
          <InstructionsText />
        </>
      ) : (
        <GeneratedInpaintsContainer />
      )}
      <DrawingCanvas key={state} />
    </Container>
  );
};

const HiddenInput = styled.input`
  width: 50px;
  height: 50px;
`;

const InstructionsText = () => (
  <TextForInstructions>
    1. Select areas of the image to edit
    <br />
    2. Write a prompt
    <br />
    3. Generate! AI will only edit what you selected
  </TextForInstructions>
);

const Container = styled.div`
  padding-bottom: 80px;
  max-width: ${MAX_WIDTH}px;

  @media (min-width: 1360px) and (max-width: 1500px) {
    margin-left: -200px;
  }
`;

const TextForInstructions = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(TextForInstructions)`
  font-size: 24px;
`;

// react icon for back button
const BackButton = styled(BsArrowLeft)`
  position: absolute;
  top: 80px;
  left: 16px;
  font-size: 24px;

  ${desktopMediaQuery} {
    top: 120px;
    left: 420px;
    &:hover {
      cursor: pointer;
      color: white;
    }
  }

  @media (min-width: 1360px) and (max-width: 1500px) {
    left: 300px;
  }
`;

export default InpaintingPage;
