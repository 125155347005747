import { FakeImage } from "../types";

export const setImagesToLocalStorage = (newImages: FakeImage[]) => {
  localStorage.setItem("images", JSON.stringify(newImages));
};

export const getImagesFromLocalStorage = (): FakeImage[] => {
  const images = localStorage.getItem("images");
  if (!images) return [];
  return JSON.parse(images);
};

export const addImageToLocalStorage = (image: FakeImage) => {
  const imagesFromLocalStorage = getImagesFromLocalStorage();
  setImagesToLocalStorage([image, ...imagesFromLocalStorage]);
};

export const removeImageFromLocalStorage = (image: FakeImage) => {
  const imagesFromLocalStorage = getImagesFromLocalStorage();
  const filteredImages = imagesFromLocalStorage.filter(
    (imageFromLocalStorage) => imageFromLocalStorage.trackId !== image.trackId
  );
  setImagesToLocalStorage(filteredImages);
};
