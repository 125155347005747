import React, { useEffect } from 'react';
import CreateTab from './CreateTab';
import BasicContainerWithFadeIn from '../../components/common/BasicContainerWithFadeIn';
import { moveToTop } from '../../hooks/helpers';

const CharactersPage: React.FC = () => {
  useEffect(() => {
    moveToTop();
  }, []);
  return (
    <BasicContainerWithFadeIn>
      <CreateTab />
    </BasicContainerWithFadeIn>
  );
};

export default CharactersPage;
