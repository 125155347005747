import styled from 'styled-components';
import AdvancedModeTab from './AdvancedPage';

const HomePage = () => {
  return (
    <Container>
      <AdvancedModeTab />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;

  min-height: 100vh;

  margin-top: 0;
  width: 100%;
`;

/*
interface Tabs {
  selectedTab: ImgGenerationTabs;
  handleTabChange: (tab: ImgGenerationTabs) => void;
}

const Tabs = ({ selectedTab, handleTabChange }: Tabs) => {
  const magicText =  'Easy mode';

  const smallScreen = window.innerWidth < 380;
  const advancedText = smallScreen ? 'Advanced' : 'Advanced mode';
  return (
    <TabsContainer>
      <TabButton
        isSelected={selectedTab === 'magic-mode'}
        onClick={() => handleTabChange('magic-mode')}
      >
        <FaMagic /> {magicText}
      </TabButton>
      <TabButton
        isSelected={selectedTab === 'advanced-mode'}
        onClick={() => handleTabChange('advanced-mode')}
      >
        <RiSettingsLine />
        {advancedText}
      </TabButton>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 16px;
`;

const TabButton = styled(StyledButton)<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px;
  width: 200px;
  transition: 0.3s;
  border-color: ${({ isSelected }) =>
    isSelected ? PRIMARY_COLOR : BORDER_COLOR};
  &:hover {
    border-color: ${PRIMARY_COLOR};
    border-width: 1px;
    margin-bottom: 0px;
  }
`;
*/
export default HomePage;
