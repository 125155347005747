import { waitSeconds } from "../components/helpers";

export const headers = {
  "Content-type": "Application/json",
  "Access-Control-Allow-Origin": "*",
};

export const moveToTop = async () => {
    await waitSeconds(0.2);
    window.scrollTo({ top: 0, behavior: "smooth" });
};
