const PrivacyNotice = () => {
  return (
    <div>
      <h1>Privacy Notice</h1>
      <p><strong>Effective Date:</strong> 16 May 2024</p>

      <p>At Picarus AI, a service of AP Software Ltd, we are committed to protecting your privacy. This Privacy Notice explains how we collect, use, disclose, and safeguard your information when you visit our website and use our AI image generation services.</p>

      <h2>1. Information We Collect</h2>
      <p>We may collect information about you in a variety of ways, including:</p>
      <ul>
        <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, email address, and other contact information that you voluntarily give to us when you create an account or contact us.</li>
        <li><strong>Usage Data:</strong> Information about how you use our services, such as the types of images generated and the features you use.</li>
        <li><strong>Technical Data:</strong> Information about your device and internet connection, such as your IP address, browser type, and operating system.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide, operate, and maintain our services.</li>
        <li>Improve, personalize, and expand our services.</li>
        <li>Communicate with you, including for customer service and support.</li>
        <li>Send you updates, promotional materials, and other information related to our services.</li>
        <li>Analyze usage patterns to improve our website and services.</li>
      </ul>

      <h2>3. Disclosure of Your Information</h2>
      <p>We may share your information with:</p>
      <ul>
        <li>Service providers that assist us in providing our services.</li>
        <li>Law enforcement agencies, if required by law.</li>
        <li>Other third parties with your consent or at your direction.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We use administrative, technical, and physical security measures to protect your personal information. While we strive to protect your data, no security measures are perfect or impenetrable.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access and review the information we hold about you.</li>
        <li>Request corrections to any inaccuracies in your information.</li>
        <li>Request the deletion of your information, subject to certain exceptions.</li>
        <li>Opt-out of receiving promotional communications from us.</li>
      </ul>

      <h2>6. Changes to This Privacy Notice</h2>
      <p>We may update this Privacy Notice from time to time. We will notify you of any changes by posting the new Privacy Notice on our website. Continued use of the service after any changes implies acceptance of the new Privacy Notice.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Notice, please contact us at hello@picarus.ai.</p>
    </div>
  );
}

export default PrivacyNotice;
