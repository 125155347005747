import { useEffect, useState } from "react";
import PageContainer from "../../components/common/PageContainer";
import { LibraryTabName } from "../../types";
import userSettingsInStorage from "../../localStorage/userSettingsInStorage";
import LibraryTopBar from "./LibraryTopBar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LIBRARY_PATH } from "../../pathNames";

const Library = () => {
  const [activeTab, setActiveTab] = useState<LibraryTabName>("history");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const activeTabInLocalStorage =
      userSettingsInStorage().activeTabInLibrary.get();

    // Kinda hack to make it work
    if (location.pathname.includes("folders/")) return;

    // Check if you're already on the correct tab to avoid unnecessary navigation. This is a kinda hack to avoid the page flashing when you navigate to a new tab
    if (location.pathname !== `${LIBRARY_PATH}/${activeTabInLocalStorage}`) {
      navigate(`${LIBRARY_PATH}/${activeTabInLocalStorage}`);
    }

    setActiveTab(activeTabInLocalStorage);
  }, [location]);

  const handleSetActiveTab = (tab: LibraryTabName) => {
    setActiveTab(tab);
    userSettingsInStorage().activeTabInLibrary.set(tab);
  };

  return (
    <PageContainer>
      <LibraryTopBar
        activeTab={activeTab}
        handleSetActiveTab={handleSetActiveTab}
      />
      <Outlet />
    </PageContainer>
  );
};

export default Library;
