import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../helpers";

const useSendEmailVerification = () => {
  const [{ token }] = useCookies(["token"]);

  const sendEmailVerification = async (
    email: string
  ): Promise<{ status: number; verificationCode?: string }> => {
    const functionName = "sendEmailVerification";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "POST",
      // token might be undefined if this is a new user registering
      //, or if the user is already logged in and just confirming a new email it exists
      body: JSON.stringify({ token, email }),
      headers,
    });

    if (response.status === 409) {
      return { status: 409 };
    }

    const data = await response.json();

    if (data.error) {
      console.log(data.error);
      return { status: 500 };
    }

    return { status: response.status };
  };

  return sendEmailVerification;
};

export default useSendEmailVerification;
