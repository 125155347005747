import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../helpers";

const useClaimFreeCredits = () => {
  const [{ token }] = useCookies(["token"]);

  const claimFreeCredits = async (
    fingerprint: string
  ): Promise<{ status: number }> => {
    if (!token) {
      console.error("Missing token");
      return { status: 500 };
    }

    const functionName = "claimFreeCredits";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    try {
      const response = await fetch(fetchUrl, {
        method: "POST",
        body: JSON.stringify({ token, fingerprint }),
        headers,
      });

      if (response.status === 420) {
      }

      return { status: response.status };
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      return { status: 500 };
    }
  };

  return claimFreeCredits;
};

export default useClaimFreeCredits;
