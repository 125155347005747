import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useGetSharedImagesWithIds from "../../hooks/userHandling/useGetSharedImagesWithIds";

/**
 * Custom hook to fetch and set an image based on the "id" URL parameter.
 * It will also open a modal if the image exists.
 */
const useImageFromParams = (
  setSelectedImage: Function,
  setIsModalOpen: Function
) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const imageIdFromParams = params.get("id");
  const getSharedImagesWithIds = useGetSharedImagesWithIds();

  useEffect(() => {
    const update = async () => {
      if (!imageIdFromParams) return;

      const imageFromParams = await getSharedImagesWithIds([imageIdFromParams]);
      if (imageFromParams.length === 1) {
        setSelectedImage(imageFromParams[0]);
        setIsModalOpen(true);
      }
    };

    update();
  }, [imageIdFromParams]);
};

export default useImageFromParams;
