import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../helpers";

const useClaimReferralCredits = () => {
  const [{ token }] = useCookies(["token"]);

  const claimReferralCredits = async (): Promise<{ status: number }> => {
    if (!token) {
      console.error("Missing token");
      return { status: 500 };
    }

    const functionName = "claimReferralCredits";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    try {
      const response = await fetch(fetchUrl, {
        method: "POST",
        body: JSON.stringify({ token }),
        headers,
      });

      return { status: response.status };
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      return { status: 500 };
    }
  };

  return claimReferralCredits;
};

export default useClaimReferralCredits;
