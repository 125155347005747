/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  DESKTOP_WIDTH,
  LIGHT_GREY_COLOR,
  PRIMARY_COLOR,
} from '../../constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageNames } from '../../types';

import {
  MAGIC_PATH,
  FEED_PATH,
  HOME_PATH,
  INPAINTING_PATH,
  LIBRARY_PATH,
  PROFILE_PATH,
} from '../../pathNames';
import { desktopMediaQuery } from '../../styleHelpers';
import { FaRegCompass } from 'react-icons/fa';
import { FaPen, FaRegHeart } from 'react-icons/fa6';
import { HiOutlineSparkles } from 'react-icons/hi';

const NavigationBar = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<PageNames>('home');

  const handleNavHome = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('home');
    navigate(HOME_PATH);
  };

  const handleNavCharacters = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('magic');
    navigate(MAGIC_PATH);
  };

  const handleNavFeed = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('feed');
    navigate(FEED_PATH);
  };

  const handleClick3 = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('library');
    navigate(LIBRARY_PATH);
  };

  const path = window.location.pathname;
  useEffect(() => {
    if (path === HOME_PATH) {
      setSelectedTab('home');
    } else if (path === FEED_PATH) {
      setSelectedTab('feed');
    } else if (path === LIBRARY_PATH) {
      setSelectedTab('library');
    } else if (path === PROFILE_PATH) {
      setSelectedTab('profile');
    } else if (path === INPAINTING_PATH) {
      setSelectedTab('home');
    } else if (path === MAGIC_PATH) {
      setSelectedTab('magic');
    }
  }, [path]);

  const isHomeSelected = selectedTab === 'home';
  const isCharactersSelected = selectedTab === 'magic';
  const isFeedSelected = selectedTab === 'feed';
  const isLibrarySelected = selectedTab === 'library';

  return (
    <Container>
      <HomeIcon isSelected={isHomeSelected} onClick={(e) => handleNavHome(e)} />

      <FeedIcon isSelected={isFeedSelected} onClick={(e) => handleNavFeed(e)} />
      <LibraryIcon
        isSelected={isLibrarySelected}
        onClick={(e) => handleClick3(e)}
      />
    </Container>
  );
};
/*
      <CharactersIcon
        isSelected={isCharactersSelected}
        onClick={(e) => handleNavCharacters(e)}
      />
*/

const Container = styled.div`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  bottom: 0px;
  left: 2px;

  padding-bottom: 36px;
  background-color: ${BACKGROUND_COLOR};
  z-index: 3;

  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.5);

  width: 99vw;
  //if on desktop, instead of being on bottom, show on left side
  @media (min-width: ${DESKTOP_WIDTH}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    width: 40px;
    bottom: unset;
    left: 0px;
    padding-top: 80px;

    height: 80%;

    // give more padding left and small border
    padding-left: 8px;

    box-shadow: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: color 0.5s, transform 0.5s ease-in-out; // Added transform transition

  // text wrap
  text-align: center;

  ${desktopMediaQuery} {
    padding: 50px;
    border-right: 1px solid ${LIGHT_GREY_COLOR};
    font-size: 28px;
    &:hover {
      color: ${PRIMARY_COLOR};
    }
  }
`;

const IconText = styled.div`
  font-size: 12px;
`;

const tranisitonToShare = `
  transition: color 0.3s, transform 0.3s ease-in-out; // Added transform transition
`;
interface IconProps {
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const AdvandedModeIcon = styled(FaPen)`
  ${tranisitonToShare}
  font-size: 20px;
  margin-top: 4px;
`;

const HomeIcon = ({ isSelected, onClick }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? <AdvandedModeIcon color={PRIMARY_COLOR} /> : <AdvandedModeIcon />}
      <IconText>Create</IconText>
    </IconContainer>
  );
};

const CharactersStyledIcon = styled(HiOutlineSparkles)`
  ${tranisitonToShare}
`;

const CharactersIcon = ({ isSelected, onClick }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? (
        <CharactersStyledIcon color={PRIMARY_COLOR} />
      ) : (
        <CharactersStyledIcon />
      )}
      <IconText>Magic</IconText>
    </IconContainer>
  );
};


const FeedStyledIcon = styled(FaRegCompass)`
  ${tranisitonToShare}
`;

const FeedIcon = ({ isSelected, onClick }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? (
        <FeedStyledIcon color={PRIMARY_COLOR} />
      ) : (
        <FeedStyledIcon />
      )}
      <IconText>Explore</IconText>
    </IconContainer>
  );
};

const LibraryStyledIcon = styled(FaRegHeart)`
  ${tranisitonToShare}
`;

const LibraryIcon = ({ isSelected, onClick }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? <LibraryStyledIcon color={PRIMARY_COLOR} /> : <LibraryStyledIcon />}
      <IconText>Library</IconText>
    </IconContainer>
  );
};

export default NavigationBar;
