import { getRecentlyUsedStylesFromLocalStorage } from '../localStorage/stylesStorage';
import { StyleInterface } from '../types';
import { getEngineUsingModelId, getExampleImageSrc } from './engineHelpers';

//Todd McFarlane style illustration

export const getDefaultStyles = (): StyleInterface[] => {
  const realisticEngine5 = getEngineUsingModelId('realistic_vision_v5.1');
  const realisticEngine4 = getEngineUsingModelId('realistic_vision_v4');
  const hentaiGoldEngine = getEngineUsingModelId('aom_v3');
  const hentai3Engine = getEngineUsingModelId('anything_v5');
  const furryAnimeEngine = getEngineUsingModelId('yiffymix_v3.3');
  const digitalBabesEngine = getEngineUsingModelId('babes_v2');
  return [
    {
      id: '1.1',
      name: 'Realistic',
      prefixPrompt: 'RAW photo',
      suffixPrompt:
        '8k uhd, dslr, soft lighting, high quality, film grain, Fujifilm XT3, hasselblad, gigapixel, detailed hair, detailed body, (detailed face and eyes:1.1), (depth of field:0.6), Leica, 8K HDR, High contrast, shadows, bokeh',
      negativePrompt:
        '(deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, mutated hands and fingers:1.4), (deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, disconnected limbs, mutation, mutated, ugly, disgusting, amputation',
      engine: realisticEngine5,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/4a4ec715-83fa-4c09-923f-a6bde001e9ea.jpeg',
    },
    {
      id: '3',
      name: 'Anime',
      prefixPrompt: 'Anime screencap',
      suffixPrompt:
        '((masterpiece)), (highly detailed:1.3), 8k wallpaper, (trending on Art Station)',
      negativePrompt:
        '(airbrushed:1.3), (extra feet:1.1), (extra hands:1.1) ((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (disfigured face and eyes:1.1), (extra limbs:1.2), (disproportionate anatomy:1.2), (incorrect body), (text:1.2), (watermarks:1.2)',
      engine: hentaiGoldEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/3cdbcbc4-96c3-4a18-aeea-e816fddde4f2.jpeg',
    },
    {
      id: '4',
      name: 'Digital',
      prefixPrompt: '(hyper realistic)',
      suffixPrompt:
        '(full body:1.1), (highly detailed face), (detailed skin), (detailed eyes), absurd resolution, masterpiece, trending on art station, ultra-detailed, (lifelike), vivid, intricate details, ((photorealistic)), visceral experience, realism, sharp lighting, deep shadows, (full vibrant colors)',
      negativePrompt:
        'ng_deepnegative_v1_75t, (low quality:1.4), logo, watermark, text, lowres, ((bad anatomy)), (bad hands), (blurry eyes), missing finger, extra digits, fewer digits, blurry, (mutated hands and fingers), (poorly drawn face), (mutation), ((deformed face)), (ugly), ((bad proportions)), (double head), logo, cropped, worst quality, close up, jpeg, humpbacked, long body, long neck, (jpeg artifacts), (fused body parts:1.2), double image, split screens, (cross eyed)',
      engine: digitalBabesEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/57daf216-41d2-4c00-9c3b-0863d466a22c.jpeg',
    },
    {
      id: '5',
      name: 'Animal manga',
      prefixPrompt: '(manga drawing)',
      suffixPrompt:
        '((masterpiece)), (highly detailed:1.3), 8k wallpaper, (trending on Art Station)',
      negativePrompt:
        '((airbrushed)), (eye contact), (worst quality, low quality:1.4), bad finger, watermark, lowres, ((bad anatomy)), ((bad hands)), missing finger, blurry, ((mutated hands and fingers)), (poorly drawn face), ((mutation)), ((deformed face)), (ugly), ((bad proportions)), (double head), monster, logo, cropped, worst quality, close up, normal quality, jpeg, humpbacked, long body, long neck, ((jpeg artifacts)), ((disembodied penis)), ((fused body parts:1.2))',
      engine: furryAnimeEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/d822c9e0-7012-4917-b831-40a06030f05c.jpeg',
    },
    {
      id: '6',
      name: 'Old School Manga',
      prefixPrompt:
        '(Miyazaki style illustration:1.2), (professional manga drawing)',
      suffixPrompt: '((masterpiece)), (highly detailed:1.3), 8k wallpaper',
      negativePrompt:
        '((airbrushed)), (eye contact), (worst quality, low quality:1.4), bad finger, watermark, lowres, ((bad anatomy)), ((bad hands)), missing finger, blurry, ((mutated hands and fingers)), (poorly drawn face), ((mutation)), ((deformed face)), (ugly), ((bad proportions)), (double head), monster, logo, cropped, worst quality, close up, normal quality, jpeg, humpbacked, long body, long neck, ((jpeg artifacts)),((fused body parts:1.2))',
      engine: hentai3Engine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/6d0d68da-1321-497c-ab1e-1893eb921d5a.jpeg',
    },
    {
      id: '7',
      name: 'Comic',
      prefixPrompt: '(comic illustration)',
      suffixPrompt: '((masterpiece)), (highly detailed:1.3)',
      negativePrompt:
        '((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (disfigured face and eyes:1.1), (worst quality:1.3), (low quality:1.3), (((watermark))), bad anatomy,, (extra limbs:1.2)',
      engine: digitalBabesEngine,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/c9588f57-3ee4-4205-9de2-de669d89af33.jpeg',
    },
    {
      id: '8',
      name: 'Polaroid photograph',
      prefixPrompt: 'candid amateur Polaroid photo, high-res, very detailed,',
      suffixPrompt: '((masterpiece)), (highly detailed:1.3), 8k wallpaper',
      negativePrompt:
        '((deformed face)),((disfigured)), ((bad art)), ((deformed)), ((extra limbs)), ((close up)), blurry, (((duplicate))), ((morbid)), ((mutilated)), (out of frame), extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), blurry, ((bad anatomy)), (disfigured face and eyes:1.1), cgi, render, 3d image, computer generated, artificial, missing limbs, extra limbs, perfect skin, misplaced nipples, airbrushed skin, malformed belly-button, contorted limbs, twisted limbs',
      engine: realisticEngine4,
      creatorUserId: '1',
      type: 'default',
      previewImageUrl:
        'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/0d142611-2942-49ca-838d-df1999fb3811.jpeg',
    },
    {
      id: '0',
      name: 'No style',
      prefixPrompt: '',
      suffixPrompt: '',
      negativePrompt: '',
      engine: undefined,
      creatorUserId: '1',
      type: 'default',
    },
  ];
};

function rearrangeNoneStyleFirst(styles: StyleInterface[]): StyleInterface[] {
  const index = styles.findIndex((style) => style.id === '0');
  if (index > -1) {
    const [item] = styles.splice(index, 1);
    styles.unshift(item);
  }
  return styles;
}

export const getDefaultStylesForAllModal = (): StyleInterface[] =>
  rearrangeNoneStyleFirst(getDefaultStyles());

export const getPreviewStyles = (): StyleInterface[] => {
  const recentlyUsedStyles = getRecentlyUsedStylesFromLocalStorage();
  const defStyles = getDefaultStyles().slice(0, 3);
  const stylesToReturn = [...recentlyUsedStyles, ...defStyles];
  return stylesToReturn.slice(0, 3);
};

export const getDefaultStyleViaId = (styleId: string): StyleInterface => {
  const noneId = '0';
  const noneStyle = getDefaultStyles().find(
    (style) => style.id === noneId,
  ) as unknown as StyleInterface;
  return getDefaultStyles().find((style) => style.id === styleId) ?? noneStyle;
};

export const getBgImageToShow = (style: StyleInterface) => {
  if (!style?.engine) {
    return 'placeholder.png';
  }
  const bgImageToShow = style.previewImageUrl
    ? style.previewImageUrl
    : getExampleImageSrc(style.engine);
  return `url(${bgImageToShow})`;
};

// useGetRecentlyUsedStyles, check recently used engines stuff copy paste here
