import { EngineType } from "../types";

const engineList: EngineType[] = [
  {
    name: "Realistic HD 2",
    modelId: "realistic_vision_v5.1",
    type: "realistic",
  },
  {
    name: "Realistic HD",
    modelId: "realistic_vision_v4",
    type: "realistic",
  },
  {
    name: "Realistic 1",
    modelId: "realistic-vision-v13",
    type: "realistic",
    isGoldOnly: true,
  },
  {
    name: "Realistic 2",
    modelId: "hardblend",
    type: "realistic",
    isGoldOnly: true,
  },
  {
    name: "Anime Gold",
    modelId: "aom_v3",
    type: "anime",
  },
  {
    name: "Anime 3",
    modelId: "anything_v5", // TODO: ehk väärä malli
    type: "anime",
  },
  {
    name: "Fur 2",
    modelId: "yiffymix_v3.3",
    type: "anime",
  },
  {
    name: "Fur",
    modelId: "furry",
    type: "anime",
    isGoldOnly: true,
  },
  {
    name: "Digital 1",
    modelId: "perfect-world",
    type: "digital",
  },
  {
    name: "Digital B",
    modelId: "babes_v2",
    type: "digital",
  },
  {
    name: "Digital 3",
    modelId: "ned_v1.22",
    type: "digital",
    isGoldOnly: true,
  },
  {
    name: "Digital 2",
    modelId: "chilloutmix",
    type: "digital",
    isGoldOnly: true,
  },
];

export const getEngineNameFromModelId = (modelId: string) =>
  engineList.find((engine) => engine.modelId === modelId)?.name ??
  "Realistic 1";

export const transformOldEngineToNew = (
  engineToTransform: EngineType
): EngineType => {
  const isNewerEngineType =
    getEngineModelId(engineToTransform.name) !== undefined;

  const newestEngine = engineList.find(
    (engine) => engine.modelId === engineToTransform.modelId
  );

  if (newestEngine) {
    return newestEngine;
  }

  if (isNewerEngineType) {
    return engineToTransform;
  }
  const defaultRealistic = {
    name: "Realistic 1",
    modelId: "realistic-vision-v13",
  };

  const defaultAnime = {
    name: "Hentai 1",
    modelId: "anything-v4",
  };

  switch (engineToTransform.name) {
    case "Photorealistic (F222)":
      return defaultRealistic;
    case "Photorealistic 2 (Realistic Vision v1.3)":
      return defaultRealistic;
    case "Photorealistic 3 (Dreamlike Photoreal 2.0)":
      return defaultRealistic;
    case "Anime (anything V3)":
      return defaultAnime;
    case "Anime (Waifu diffusion)":
      return defaultAnime;
    case "Photorealistic (Protogen)":
      return defaultRealistic;
    case "Vintedoi (very realistic 3d art)":
      return defaultRealistic;
    case "3d art (Redshift)":
      return defaultRealistic;
    case "Modelshoot":
      return defaultRealistic;
    case "Analog":
      return defaultRealistic;
    case "Midjourney v4":
      return defaultRealistic;
    case "Synthwave punk (SFW)":
      return defaultRealistic;
    default:
      return defaultRealistic;
  }
};

export const getEngineNames = () => engineList.map((engine) => engine.name);

export const getEngines = () => engineList;

export const getEngineUsingModelId = (modelId: string): EngineType =>
  engineList.find((engine) => engine.modelId === modelId) ?? engineList[0];

// on local storage
export const getRecentlyUsedEngines = (): EngineType[] => {
  const recentlyUsed = localStorage.getItem("recentlyUsedEngines");
  if (recentlyUsed) {
    // take the modelId and refresh the list from engineList
    const refreshedRecentlyUsed = JSON.parse(recentlyUsed).map(
      (engine: EngineType) => {
        const refreshedEngine = engineList.find(
          (engineFromList) => engineFromList.modelId === engine.modelId
        );
        if (refreshedEngine) {
          return refreshedEngine;
        }
        return engine;
      }
    );
    return refreshedRecentlyUsed;
  }
  return [];
};

export const getEngineModelId = (name: string) =>
  engineList.find((engine) => engine.name === name)?.modelId;

export const getEnginesByType = (type: "realistic" | "anime" | "digital") =>
  engineList.filter((engine) => engine.type === type);

export const getEngineTypeWithModelId = (modelId: string) =>
  engineList.find((engine) => engine.modelId === modelId)?.type ?? "realistic";

export const getExampleImageSrc = (engine: EngineType) => {
  switch (engine.modelId) {
    case "realistic-vision-v13":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/667512c8-5df9-4710-bd8e-749bffb7627c.jpeg";
    case "realistic-vision-14":
      return "https://d12mvgqqknpvkx.cloudfront.net/222b3c82-9d22-4e6e-9197-ea04636f0d38-0.png";
    case "hardblend":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/af6d2b22-3ac3-4896-a80f-9ca6b277b776.jpeg";
    case "realistic-men":
      return "https://serve-model-sd-outputs.s3.amazonaws.com/d6d8cb45-0a75-4df5-a0cb-52b0e8f0e762.png";
    case "anything-v4":
      return "https://serve-model-sd-outputs.s3.amazonaws.com/35ca6878-330d-4829-a3db-2e85a33093e4.png";
    case "anything-v3":
      return "https://d12mvgqqknpvkx.cloudfront.net/ffd947e3-8ae3-4a66-9734-72c58fb9edb1-0.png";
    case "furry":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/f490ff75-b631-4057-a7ea-f59df8618293.jpeg";
    case "perfect-world":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/8c917c86-c620-4b40-af10-cae0f316c347.jpeg";
    case "chilloutmix":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/ee935e0d-b519-4e13-bf17-2e1d9b15d230.jpeg";
    case "ned_v1.22":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/53280e20-fe7a-4f85-8d9a-de06e0da902b.jpeg";
    case "reliberate_v2":
      return "https://serve-model-sd-outputs.s3.amazonaws.com/1693984263-a4c60bae-b3b6-4e01-bfc8-6407b809336c-sd.png";
    case "babes_v2":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/dcde0191-818b-4537-9f21-e88c7eb29f0d.jpeg";
    case "realistic_vision_v4":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/4d4336dc-d129-4d2d-9090-3027e022926f.jpeg";
    case "realistic_vision_v5.1":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/4a4ec715-83fa-4c09-923f-a6bde001e9ea.jpeg";
    case "aom_v3":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/a6ee20d6-e4a4-4dca-8727-d4e113e9471b.jpeg";
    case "anything_v5":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/ced2f88f-5ea3-40af-951c-662d03fb3b25.jpeg";
    case "yiffymix_v3.3":
      return "https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/91456b6b-a55e-4b07-8006-3bcf8b6a939c.jpeg";
    case "absoluteReality_v1.6":
      return "https://serve-model-sd-outputs.s3.amazonaws.com/26ff881c-bdd0-4585-b7f0-ffe41fd9a08a.png";
  }
  return "https://serve-model-sd-outputs.s3.amazonaws.com/1692160890-d1cb3b5a-b1d7-4ca0-aef6-863c2c49fb96-sd.png";
};

export const getEngineDescription = (engine: EngineType) => {
  switch (engine.modelId) {
    case "realistic-vision-v13":
      return "Good for creating anything realistic-looking";
    case "realistic-vision-14":
      return "A similar engine to R1, but may need more specific prompts to get good results";
    case "hardblend":
      return "Creates hyperrealistic images, that might look slightly digital";
    case "furry":
      return "Good for creating animal characters. Also excels in digital and drawn images";
    case "anything-v4":
      return " Good for creating anime images, but also anything drawn. Creates more 'modern' images.";
    case "anything-v3":
      return "More old-schoolish drawing style";
    case "perfect-world":
      return "Great for digital or hyperrealistic images.";
    case "chilloutmix":
      return "Similar to digital 1, more digital-like";
    case "ned_v1.22":
      return "Leans towards 'drawn-digital'. Great at cosplay, anime, realistic people, 'booru'-tags & animals";
    case "reliberate_v2":
      return "Fairly simple to prompt. Excellent at photography-style images";
    case "babes_v2":
      return "Realistic digital images. Important to add 'quality enhancers' to the prompt, e.g. ((masterpiece))";
    case "realistic_vision_v4":
      return "The next version of the realistic engine. Similar to R1";
    case "realistic_vision_v5.1":
      return "The better Gold version of Realistic HD";
    case "aom_v3":
      return "Simple to prompt. Illustrations with very realistic textures and can generate a wide variety of content";
    case "anything_v5":
      return "Generates similar images to hentai 2, but better";
    case "yiffymix_v3.3":
      return "Very versatile engine capable of digital, drawn, and animalistic images, or just about anything";
    case "absoluteReality_v1.6":
      return "Slightly digital-leaning images. Use simple short prompts for best results";
  }
  return "No description available.";
};

export {};
