import styled from "styled-components";
import { BORDER_COLOR, PRIMARY_COLOR } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

// TODO: REFACTOR
export const InputContainer = styled.div<{ withIcon?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.withIcon ? "row" : "column")};
  background-color: transparent;
  width: 100%;
  padding-right: 0px;
  justify-content: space-between;
  align-items: ${(props) => (props.withIcon ? "center" : "flex-start")};
  margin-bottom: 16px;
  z-index: 1;

  padding: 2px;

  border: 1.5px solid ${BORDER_COLOR};
  border-radius: 8px;

  transition: border-color 0.2s;
  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }
`;
