import { useEffect, useState } from "react";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../../hooks/helpers";
import { FakeImage } from "../../types";
import { useGeneralContext } from "../../context/GeneralContextProvider";
import Loader from "./Loader";
import { PLACEHOLDER_IMAGE_PATH } from "../../components/ImageStuff/GeneratedImage";
import styled from "styled-components";
import useProgressTracking from "../../hooks/images/useProgressTracking";
import { useImagesContext } from "../../context/ImageContextProvider";
import { addImageToLocalStorage } from "../../localStorage/imageStorage";
import { fadeInWithBounce } from "../../components/ImageStuff/animations";

type P = {
  image: FakeImage;
};
const GeneratedInpaint = ({ image }: P) => {
  const [imageUrl, setImageUrl] = useState<string>(image.imageUrl);
  const {
    loading,
    setLoading,
    generationStartedTimestamp,
    generationReadyTimestamp,
    setImages,
  } = useGeneralContext();

  const { editedImages, setEditedImages } = useImagesContext();

  const { progress, timeLeft } = useProgressTracking(
    generationStartedTimestamp,
    generationReadyTimestamp,
    image.generating
  );

  useEffect(() => {
    const shouldStartFetching = () => {
      const MS_TO_MINUS_TO_TIMESTAMP = 6500;
      const now = Date.now();

      return now >= generationReadyTimestamp - MS_TO_MINUS_TO_TIMESTAMP;
    };

    /* if the trackId is in editedImages and the trackId has imageUrl not "generating"
     ** Fixes a bug where it keeps fetching for some reason
     */
    const hasFetchedTheImageAlready = () =>
      editedImages.some(
        (editedImage) =>
          editedImage.trackId === image.trackId &&
          editedImage.imageUrl !== "generating"
      );

    const DELAY_BETWEEN_FETCHES = 3000;

    // TODO: refactor
    const fetchInpainting = async () => {
      if (!shouldStartFetching()) {
        return;
      }
      if (hasFetchedTheImageAlready()) {
        return;
      }
      const baseUrl = getBaseUrlForFetching();
      const functionName = "getImageData";
      const fetchUrl = `${baseUrl}/${functionName}`;
      const response = await fetch(fetchUrl, {
        method: "POST",
        headers,
        body: JSON.stringify({
          trackId: image.trackId,
        }),
      });
      const imageFromDb = await response.json();

      // bug: keeps fetching old images on preview

      // keep fetching until image is done
      if (imageFromDb.imageUrl && imageFromDb.imageUrl !== "generating") {
        setImageUrl(imageFromDb.imageUrl);
        // replace the one with the same trackId with the new data
        setEditedImages((prev) => {
          const newEditedImages = prev.map((image) => {
            if (image.trackId === imageFromDb.trackId) {
              return imageFromDb;
            }
            return image;
          });
          return newEditedImages;
        });

        //set images to history
        setImages((images) => images.concat(imageFromDb));
        addImageToLocalStorage(imageFromDb);

        setLoading(false);
        clearInterval(interval);
        return;
      }
    };

    let interval: NodeJS.Timeout;
    interval = setInterval(fetchInpainting, DELAY_BETWEEN_FETCHES);

    return () => clearInterval(interval);
  }, []);

  const urlToShow =
    imageUrl === "generating" ? PLACEHOLDER_IMAGE_PATH : imageUrl;

  const maxHeight = image ? image.height : 512;
  return (
    <>
      <StyledImage
        style={{
          maxHeight: `${maxHeight}px`,
        }}
        key={imageUrl}
        src={urlToShow}
        alt="Edited image"
      />
      {loading && urlToShow === PLACEHOLDER_IMAGE_PATH && (
        <Loader
          loading={loading}
          loaderToChoose={1}
          progress={progress}
          timeLeft={timeLeft}
          height={maxHeight}
        />
      )}
    </>
  );
};

export default GeneratedInpaint;

const StyledImage = styled.img`
  opacity: 0;
  transform: scale(0.98);
  animation: ${fadeInWithBounce} 0.4s ease-in-out forwards 1.1s; // Adding a delay before

  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  margin-bottom: 8px;
`;
