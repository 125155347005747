import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { useImagesContext } from "../../context/ImageContextProvider";
import useRemoveImageFromShared from "../../hooks/images/useRemoveImageFromShared";
import { desktopStyleToAdd } from "../../styleHelpers";
import { SharedImage } from "../../types";
import SharedImageBig from "./SharedImageBig";
import { BiUpvote } from "react-icons/bi";

const SharedImages = () => {
  const { sharedImages } = useImagesContext();
  const [items, setItems] = useState<SharedImage[]>([]);
  const sortedImages = sharedImages.sort(
    (a, b) => new Date(b.sharedAt).getTime() - new Date(a.sharedAt).getTime()
  );
  const removeImageFromShared = useRemoveImageFromShared();

  const totalAmountOfRemixes = sharedImages.reduce(
    (acc, curr) => acc + curr.amountOfRemixes,
    0
  );

  /*
  const emoji = () => {
    if (totalAmountOfRemixes === 0) return "😢";
    if (totalAmountOfRemixes < 10) return "😊";
    if (totalAmountOfRemixes < 25) return "😎";
    if (totalAmountOfRemixes < 50) return "🤩";
    if (totalAmountOfRemixes < 100) return "🤯";
    if (totalAmountOfRemixes < 250) return "🔥🤩";
    return "🔥🤯🤩😎";
  };
  */
  const SHOW_X_PER = 10;
  useEffect(() => {
    setItems(sortedImages.slice(0, SHOW_X_PER));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedImages]);

  if (!sharedImages.length) return <Container>No shared images</Container>;

  const fetchMore = () => {
    const nextItems = sortedImages.slice(
      items.length,
      items.length + SHOW_X_PER
    );
    setItems(items.concat(nextItems));
  };

  const handleRemoveFromSharedImages = (sharedImage: SharedImage) => {
    const areTheySure = window.confirm(
      "Are you sure you want to remove this image from shared images?"
    );
    if (!areTheySure) {
      return;
    }
    removeImageFromShared(sharedImage);
  };

  const hasMore = items.length < sharedImages.length;
  const dataLength = items.length;

  return (
    <Container id="scrollableDiv">
      <TotalRemixesText>
        <BiUpvote size={18} /> Total karma: {totalAmountOfRemixes}
      </TotalRemixesText>
      <br />
      <div
        style={{
          fontSize: "14px",
          marginBottom: "16px",
        }}
      >
        You earn karma when someone remixes or saves your image
      </div>
      Your shared images:
      <InfiniteScroll
        dataLength={dataLength}
        next={fetchMore}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        scrollThreshold={0.9}
        style={{
          width: "100%",
          height: "100%",
          marginTop: "16px",
        }}
      >
        {items.map((sharedImage) => (
          <SharedImageBig
            key={sharedImage.id}
            sharedImage={sharedImage}
            handleRemove={handleRemoveFromSharedImages}
          />
        ))}
      </InfiniteScroll>
    </Container>
  );
};

const TotalRemixesText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  margin-bottom: 16px;

  gap: 4px;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: 100%;
  max-width: 410px;
  ${desktopStyleToAdd}
`;

export default SharedImages;
