import styled from "styled-components";
import { BACKGROUND_COLOR } from "../../../constants";
import { FakeImage, FolderInterface } from "../../../types";
import TransitioningModal from "../TransitioningModal";

import AddNewFolderModal from "./AddNewFolderModal";
import { useState } from "react";
import useCreateNewFolder from "../../../hooks/folders/useCreateNewFolder";
import { toast } from "react-toastify";
import useSaveImageToFolder from "../../../hooks/folders/useSaveImageToFolder";
import { useLoggedInUserContext } from "../../../context/LoggedInUserContextProvider";
import FoldersList from "./FoldersList";
import { TIME_TO_CLOSE_TOAST } from "../../../MainApp";

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
  image: FakeImage;
}

const SaveToFolderModal = ({ show, setShow, image }: I) => {
  const { loggedInUser } = useLoggedInUserContext();
  const folders = loggedInUser?.folders;

  const createNewFolder = useCreateNewFolder();
  const saveImageToFolder = useSaveImageToFolder();

  const [showAddNewFolderModal, setShowAddNewFolderModal] = useState(false);

  const successToast = () => {
    toast.success("Saved successfully", { autoClose: TIME_TO_CLOSE_TOAST });
  };

  const handleSaveToFolder = async (folder: FolderInterface) => {
    await saveImageToFolder(folder, image);

    successToast();

    setShow(false);
  };

  const handleCreateNewFolder = async (folderName: string) => {
    const { status } = await createNewFolder(folderName, image);

    if (status !== 200) {
      toast.error("Error creating folder");
      return;
    }
    toast.success("Saved successfully");
    setShow(false);
  };

  return (
    <TransitioningModal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 200,
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: "16px",
          width: "100%",
          maxWidth: "500px",
          minHeight: "300px",
          maxHeight: "600px",
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={() => setShow(false)}
    >
      <Caption>Choose a Folder</Caption>

      <FoldersList
        folders={folders}
        handleSaveToFolder={handleSaveToFolder}
        setShowAddNewFolderModal={setShowAddNewFolderModal}
      />

      <AddNewFolderModal
        showModal={showAddNewFolderModal}
        setShowModal={setShowAddNewFolderModal}
        handleCreateNewFolder={handleCreateNewFolder}
      />
    </TransitioningModal>
  );
};
const Caption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SaveToFolderModal;
