import { FiTrash } from "react-icons/fi";
import styled from "styled-components";
import { IconButtonContainer, sharedIconStyle } from "./IconButtonStuff";

interface P {
  onClick: () => void;
  inLibrary?: boolean;
}

// TODO: refactor so takes in image instead on onClick
export default function RemoveButton({ onClick, inLibrary }: P) {
  return (
    <IconButtonContainer onClick={onClick}>
      <RemoveButtonIcon inLibrary={inLibrary} />
    </IconButtonContainer>
  );
}

const RemoveButtonIcon = styled(FiTrash)<{ inLibrary?: boolean }>`
  ${sharedIconStyle}
  margin-bottom: ${({ inLibrary }) => (inLibrary ? "2px" : "0px")};
  margin-left: ${({ inLibrary }) => (inLibrary ? "4px" : "0px")};
`;
