import styled from "styled-components";
import {
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../constants";
import { GenericSetState } from "../../types";
import userSettingsInStorage from "../../localStorage/userSettingsInStorage";
import { desktopMediaQuery } from "../../styleHelpers";

interface I {
  isAdvancedModeEnabled: boolean;
  setIsAdvancedModeEnabled: GenericSetState<boolean>;
}

const ShowAdvancedButton = ({
  isAdvancedModeEnabled,
  setIsAdvancedModeEnabled,
}: I) => {
  const handleOnClick = () => {
    setIsAdvancedModeEnabled((b) => {
      setAdvancedModeToLocalStorage(!b);
      return !b;
    });
  };

  const setAdvancedModeToLocalStorage = (b: boolean) => {
    userSettingsInStorage().advancedSettings.set(b);
  };

  const text = isAdvancedModeEnabled
    ? "Hide advanced options"
    : "Show advanced options";

  return <ShowMoreButton onClick={handleOnClick}>{text}</ShowMoreButton>;
};

const ShowMoreButton = styled.button`
  border-radius: 8px;
  padding: 16px;
  font-size: 16px;
  border: 1px solid ${BORDER_COLOR};
  margin-top: 10px;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;

  padding: 8px;
  width: 200px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;

  transition: border-color 0.2s;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      border-color: ${PRIMARY_COLOR};
    }
  }
`;

export default ShowAdvancedButton;
