import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import TransitioningModal from '../TransitioningModal';
import {
  DISCORD_URL,
  DiscordLogo,
  REDDIT_URL,
  RedditLogo,
} from '../../common/TopBar/SocialLogos';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const NotificationModal = ({ showModal, setShowModal }: I) => {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
            padding: '30px',
            width: '78%',
            maxHeight: '90vh',
            maxWidth: '350px',
            margin: 'auto',
            marginTop: '1vh',
            paddingBottom: '40px',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={handleClose}
      >
        <CloseButtonInModal
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: PRIMARY_TEXT_COLOR,
          }}
          onClick={handleClose}
        />
        <ScrollableDiv>
          <TitleText>🎉 Community Hub</TitleText>

          <List>
            <b>Have you checked out our Reddit and Discord?</b>
            <p>⭐ Get special Role from #gold-confirmation-channel</p>
            <p>
              🏆 Weekly competitions on both; earn 300 credits for creating the
              best image!
            </p>
            <p>🆘 Get help from other users in learning to create images</p>
            <p>💬 Talk with The Developer and power users</p>
          </List>

          <ContainerForSocials>
            <LogoContainer onClick={() => window.open(DISCORD_URL, '_blank')}>
              Discord <DiscordLogo />
            </LogoContainer>

            <LogoContainer onClick={() => window.open(REDDIT_URL, '_blank')}>
              Reddit <RedditLogo />
            </LogoContainer>
          </ContainerForSocials>
        </ScrollableDiv>
      </Modal>
    </>
  );
};

const ContainerForSocials = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;

  border: 1px solid ${BORDER_COLOR};

  &:hover {
    cursor: pointer;
    color: ${PRIMARY_COLOR};
    border-color: ${PRIMARY_COLOR};
  }
`;

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 16px;

  text-align: left;
`;

const List = styled(ModalText)`
  margin-top: -8px;
`;

const TitleText = styled(ModalText)`
  font-size: 20px;
  text-align: center;
  margin-bottom: 22px;
`;

const ScrollableDiv = styled.div`
  overflow-y: auto;
  max-height: 80vh;
  padding-right: 16px;
  margin-top: 16px;
`;

export default NotificationModal;
