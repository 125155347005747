import { FakeImage } from "../../types";

/*
TODO: try if you can use this instead to download always instead of new tab
function downloadImage(imageUrl) {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = 'true'; // This can also be a specific filename
  document.body.appendChild(link); // Append to body
  link.click(); // Simulate click
  document.body.removeChild(link); // Clean up
}

const url = image.imageUrl;
downloadImage(url);
*/
export function useHandleDownloadImage() {
  const handleDownloadImage = (image: FakeImage) => {
    if (!image.imageUrl) return;
    const url = image.imageUrl;
    window.open(url, "_blank");
  };
  return handleDownloadImage;
}
