export const getBadWord = (prompt: string) => {
  const badWords = [
    'nude',
    'boobs',
    'tits',
    'tit',
    'boob',
    'breast',
    'breasts',
    'naked',
    'vagina',
    'pussy',
    'no clothes',
    'topless',
    'nsfw',
    'loli',
    'child',
    'rape',
    'shit',
    'poop',
    'piss',
    'porn',
    'sexy',
    'sex',
    'ass',
    'penis',
    'anus',
    'butt'
  ];
  const words = prompt.split(/[\s.,()]+/);
  const badWord = words.find((word) => badWords.includes(word.toLowerCase()));
  return badWord;
};
