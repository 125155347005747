import React from 'react';
import styled from 'styled-components';
import {
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../constants';

type ExampleTagsPromptBoxProps = {
  handleClickedTag: (tag: string) => void;
  tags: string[];
};

const ExampleTagsPromptBox: React.FC<ExampleTagsPromptBoxProps> = ({
  handleClickedTag,
  tags,
}) => {
  return (
    <Container>
      <HintText>Click ➡</HintText>
      {tags.map((tag, index) => (
        <Tag key={index} onClick={() => handleClickedTag(tag)}>
          {tag}
        </Tag>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 4px;
  padding: 8px;
  align-items: center;
  justify-content: flex-start;

  overflow-x: auto;
  max-width: 600px;

  scrollbar-width: thin;

  margin-left: -8px;
  margin-top: -12px;
`;

const HintText = styled.div`
  font-size: 14px;
  color: ${PRIMARY_TEXT_COLOR};
  margin-right: 8px;
  white-space: nowrap;

  margin-left: 2px;
`;

const Tag = styled.div`
  text-align: center;
  width: auto;
  height: 20px;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 14px;

  //#261f30;
  // #d707a321;
  background-color: #36313d6e;
  //border: 1px solid ${BORDER_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: bold;

  letter-spacing: -0.4px;

  cursor: pointer;
  transition: border-color 0.3s;
  white-space: nowrap;

  &:hover {
    border-color: ${PRIMARY_COLOR};
  }
`;

export default ExampleTagsPromptBox;
