import { DEFAULT_PROMPT } from '../constants';
import { EngineType, FakeImage } from '../types';
import { PLACEHOLDER_IMAGE_PATH } from './ImageStuff/GeneratedImage';

import { v4 as uuidv4 } from 'uuid';

const MAX_SEED = 4294967295;

export const getRandomSeed = () => {
  return Math.floor(Math.random() * MAX_SEED);
};

/**
 * Generates a random track id using uuidv4
 */
export const generateTrackId = () => {
  const trackId = uuidv4();
  return trackId + "-pic" // Add a suffix since handleWebhookResponseGeneration happens in OnlyFakes repo atm
};

export const waitSeconds = (seconds: number) =>
  new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const getFailedImage = (): FakeImage => {
  return {
    imageUrl: PLACEHOLDER_IMAGE_PATH,
    trackId: 'failed',
    guidanceScale: 7.5,
    engine: {
      name: 'Realistic women 1',
      modelId: 'realistic-vision-v13',
    },
    negativePrompt: '',
    prompt: '',
    seed: 1,
    height: 512,
    width: 512,
  };
};

export const cleanUpPrompt = (prompt: string) => {
  const stringsToRemove = [
    'analog style ',
    'mdjrny-v4 style ',
    'modelshoot style ',
    'redshift style ',
    'synthwave style ',
    'estilovintedois ',
    'nvinkpunk ',
  ];

  return stringsToRemove.reduce((acc, str) => {
    return acc.replace(str, '').trim();
  }, prompt);
};

/*
Remove string from prompt
Filters out the words that are in 
*/
export const getNewNegativePrompt = (negativePrompt: string): string => {
  const stringToRemove = '';
  return negativePrompt.replace(stringToRemove, '').trim();
};

export {};

export const transformPrompt = (prompt: string) => {
  if (prompt === '') {
    return DEFAULT_PROMPT;
  }
  return prompt;
};

const creditsPerGeneration: { [key: string]: number } = {
  normal: 0,
  fast: 1,
  turbo: 3,
};

/**
 * Note: if changing this, remember to change in functions/helpers as well!
 * 
 * Calculates the number of credits required to generate images based on speed mode, quantity, and engine type.
 * Credits are based on speed mode and quantity; 'normal' mode charges only for 2+ images, while 'fast' and 'turbo' modes charge per image. Gold engines incur additional 1 credit per image.
 *
 * @param {string} speedMode - The mode of speed ('normal', 'fast', 'turbo').
 * @param {number} [amountToGenerate=1] - The number of images to generate. Defaults to 1.
 * @param {EngineType} [engine] - Optional engine specification, including if it's a gold type engine.
 * @returns {number} - The total number of credits required for the operation.
 * 
 * @example
 * // Returns 0 for 1 image in normal mode
 * getCreditsRequired('normal', 1);
 *
 * @example
 * // Returns 5 for 2 images in turbo mode with a gold engine
 * getCreditsRequired('turbo', 2, { name: 'GoldEngine', isGoldOnly: true });

 */
export const getCreditsRequired = (
  speedMode: string,
  amountToGenerate: number = 1,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  engine?: EngineType,
): number => {
  let creditsToGenerate = 0;

  // Calculate base credits based on speedMode and amountToGenerate
  if (speedMode === 'normal') {
    creditsToGenerate = amountToGenerate > 1 ? amountToGenerate - 1 : 0;
  } else {
    creditsToGenerate = creditsPerGeneration[speedMode] * amountToGenerate;
  }

  if (engine?.isGoldOnly) {
    creditsToGenerate += amountToGenerate;
  }
  /*
  if (speedMode === "turbo" && engine?.modelId === "realistic_vision_v5.1") {
    creditsToGenerate = 1 * amountToGenerate;
  }*/
  return creditsToGenerate;
};
