import { AiOutlineLink } from "react-icons/ai";
import { toast } from "react-toastify";
import styled from "styled-components";
import { FEED_BASE_URL } from "../../constants";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import { SharedImage } from "../../types";
import { sharedIconStyle, IconButtonContainer } from "./IconButtonStuff";

interface I {
  image: SharedImage;
  inFeedModal?: boolean;
}

const CopyLinkToClipboardIcon = ({ image, inFeedModal }: I) => {
  const { loggedInUser } = useLoggedInUserContext();
  const userIdOfTheSharer = loggedInUser?.id ?? "";
  const urlToSharedImage = loggedInUser
    ? `${FEED_BASE_URL}?id=${image.id}&r-id=${userIdOfTheSharer}`
    : `${FEED_BASE_URL}?id=${image.id}`;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(urlToSharedImage);
    toast.success("Copied! Share this link to earn free credits! 🥳");
  };
  return (
    <CopyLinkContainer inFeedModal={inFeedModal}>
      <StyldOutlineLinkIcon onClick={handleCopyLink} />
    </CopyLinkContainer>
  );
};

const StyldOutlineLinkIcon = styled(AiOutlineLink)`
  ${sharedIconStyle}
  font-size: 27px;
`;

const CopyLinkContainer = styled(IconButtonContainer)<{
  inFeedModal?: boolean;
}>`
  gap: 2px;

  padding-bottom: ${({ inFeedModal }) => (inFeedModal ? "15px" : "0")};
`;

export default CopyLinkToClipboardIcon;
