import styled from "styled-components";
import StyledSelect from "./StyledSelect";

type P = {
  value: string;
  setValue: (value: string) => void;
  selectableValues: string[];
  label: string;
};

const GenericDropdown = ({ value, setValue, selectableValues, label }: P) => {
  const handleChange = (e: any) => {
    const newCategory = e.target.value;
    if (typeof newCategory !== "string") {
      console.error("value is not a string");
      return;
    }
    setValue(newCategory);
  };

  return (
    <Container>
      <Label>{label}</Label>
      <SmallerSelect value={value} onChange={(e) => handleChange(e)}>
        {selectableValues.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SmallerSelect>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  align-self: flex-start;
`;

const SmallerSelect = styled(StyledSelect)`
  width: 130px;
`;

const Label = styled.div``;

export default GenericDropdown;
