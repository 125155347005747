import ProfileTopBar from "./ProfileTopBar";
import SharedImages from "./SharedImages";
import PageContainer from "../../components/common/PageContainer";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import LoginOrRegisterPage from "./LoginOrRegisterPage";
import { useEffect } from "react";
import useGetSharedImagesWithIds from "../../hooks/userHandling/useGetSharedImagesWithIds";
import { useImagesContext } from "../../context/ImageContextProvider";
import { SharedImage } from "../../types";
import Tooltip from "../../components/common/Tooltip";

const ProfilePage = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const getSharedImagesWithIds = useGetSharedImagesWithIds();
  const { sharedImages, setSharedImages } = useImagesContext();

  useEffect(() => {
    const update = async () => {
      if (
        !loggedInUser ||
        !loggedInUser.sharedImages.length ||
        // If we already have the shared images, don't fetch them again
        sharedImages.length >= loggedInUser.sharedImages.length
      ) {
        return;
      }

      const fetchedSharedImages: SharedImage[] = await getSharedImagesWithIds(
        loggedInUser.sharedImages
      );
      fetchedSharedImages && setSharedImages(fetchedSharedImages);
    };
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  if (!loggedInUser) {
    return <LoginOrRegisterPage />;
  }

  return (
    <PageContainer>
      <ProfileTopBar />
      <Tooltip text="This page is still under construction 🏗️ Updates coming soon" />
      <SharedImages />
    </PageContainer>
  );
};

export default ProfilePage;
