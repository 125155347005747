import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

interface ImageProps {
  url: string;
  prompt: string;
}

const exampleImages: ImageProps[] = [
  { url: "example1.jpg", prompt: "busty blonde sitting on a desk" },
  { url: "example2.jpg", prompt: "blonde ponytail, big boobs" },
  {
    url: "example3.jpg",
    prompt: "topless girl standing in rain",
  },
];

const useUpdateImages = (exampleImages: any[], timePerImage: number) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % exampleImages.length);
    }, timePerImage);

    return () => clearTimeout(timer);
  }, [currentIndex, exampleImages]);

  return currentIndex;
};

const ExampleCarousel = () => {
  const currentIndex = useUpdateImages(exampleImages, 5000);

  return (
    <Container key={currentIndex}>
      <StyledImg
        src={exampleImages[currentIndex].url}
        key={currentIndex}
        alt="Example image made with AI"
        loading="lazy"
      />
      <PromptForImage
        text={exampleImages[currentIndex].prompt}
        key={`${currentIndex}prompt`}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledImg = styled.img<{ faster?: boolean }>`
  animation: ${fadeIn} ${({ faster }) => (faster ? 1 : 2)}s ease-in-out;

  // 512 x 768 scaled down
  height: 420px;
  width: 280px;

  margin-bottom: 16px;

  border-radius: 36px;
`;

const PromptForImage: React.FC<PromptProps> = ({ text }) => {
  return <ExamplePromptText>{text}</ExamplePromptText>;
};

const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

const blinkCaret = keyframes`
  50% { border-color: transparent; }
`;

const ExamplePromptText = styled.p`
  overflow: hidden;
  border-right: 0.15em solid;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: ${typing} 1.5s steps(30, end),
    ${blinkCaret} 0.75s step-end infinite;
`;

interface PromptProps {
  text: string;
}

export default ExampleCarousel;

const editImageExamples = ["editExample1.jpg", "editExample2.jpg"];

export const EditYourImagesExamples = () => {
  const currentIndex = useUpdateImages(editImageExamples, 3000);

  return (
    <Container key={currentIndex}>
      <StyledImg
        src={editImageExamples[currentIndex]}
        key={currentIndex}
        alt="Example image edited with AI"
        faster
      />
    </Container>
  );
};
