import styled from "styled-components";
import { LIGHT_GREY_COLOR } from "../../constants";

export const SeparationLine = styled.div`
  align-self: center;
  width: 100%;
  height: 2px;
  background-color: ${LIGHT_GREY_COLOR};
  margin-bottom: 32px;
  margin-top: -80px;
`;
