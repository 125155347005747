import { ENGINE_CATEGORIES } from "../pages/FeedPage/EngineFilterDropdown";

const feedSettingsInStorage = () => {
  const nameInLocalStorage = "feedSettings";
  const storageContents = localStorage.getItem(nameInLocalStorage);

  const category = {
    get: (): string => {
      if (!storageContents) {
        return ENGINE_CATEGORIES[0];
      }
      const { category } = JSON.parse(storageContents);

      if (!category) {
        return ENGINE_CATEGORIES[0];
      }

      return category;
    },
    set: (value: string): void => {
      const sortByInSettings = sortBy.get();
      localStorage.setItem(
        nameInLocalStorage,
        JSON.stringify({
          category: value,
          sortBy: sortByInSettings,
        })
      );
    },
  };

  const sortBy = {
    get: (): string => {
      if (!storageContents) {
        return "Best past month";
      }
      const { sortBy } = JSON.parse(storageContents);

      if (!sortBy) {
        return "Best past month";
      }

      return sortBy;
    },
    set: (value: string): void => {
      const categoryInSettings = category.get();
      localStorage.setItem(
        nameInLocalStorage,
        JSON.stringify({
          sortBy: value,
          category: categoryInSettings,
        })
      );
    },
  };

  return {
    category,
    sortBy,
  };
};

export default feedSettingsInStorage;
