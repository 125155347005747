import { useRef } from 'react';
import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../../constants';
import { MdOutlineFileUpload } from 'react-icons/md';
import DenoisingStrengthSlider from './DenoisingStrengthSlider';
import useHandleReferenceImageUploading from './useHandleReferenceImageUploading';
import { useImageGenerationSettingsContext } from '../../../context/ImageGenerationSettingsProvider';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';

interface UploadReferenceImageProps {
  setShowHintToRegisterModal?: (show: boolean) => void;
}

const UploadReferenceImage: React.FC<UploadReferenceImageProps> = ({
  setShowHintToRegisterModal,
}) => {
  const {
    uploadedImageUrl,
    setUploadedImageUrl,
    denoisingStrength,
    setDenoisingStrength,
    setSize,
    isCreatingVariations,
    setIsCreatingVariations,
  } = useImageGenerationSettingsContext();

  const { isUploading, handleImageUpload } = useHandleReferenceImageUploading(
    setUploadedImageUrl,
    setSize,
  );

  const { loggedInUser } = useLoggedInUserContext();

  const handleClick = () => {
    if (isCreatingVariations) return;
    if (!loggedInUser && setShowHintToRegisterModal) {
      setShowHintToRegisterModal(true);
      return;
    }
    setDenoisingStrength(0.6);
    fileInputRef.current?.click();
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleRemoveUploadedImage = () => {
    setUploadedImageUrl('');
    setIsCreatingVariations(false);
  };

  const DISABLE = false;

  if (
    DISABLE &&
    !isCreatingVariations &&
    !['alpo', 'joni-testaa'].includes(loggedInUser?.username ?? "")
  )
    return (
      <UploadContainer>🚧 Reference Image is in maintenance 🚧</UploadContainer>
    );

  return (
    <UploadContainer>
      <UploadBox onClick={handleClick}>
        {uploadedImageUrl ? (
          <ContainerWhenImageUploaded>
            <RemoveUploadedImage onClick={handleRemoveUploadedImage}>
              X
            </RemoveUploadedImage>
            <ImagePreview src={uploadedImageUrl} alt="Uploaded" />
          </ContainerWhenImageUploaded>
        ) : (
          <>
            <MdOutlineFileUpload size={24} />
            <UploadText>Upload a refence image</UploadText>
            <UploadInstructions>
              AI takes inspiration from this image. It won't keep the same face.
            </UploadInstructions>
            <HiddenInput
              type="file"
              accept=".png,.jpg,.jpeg,.webp"
              onChange={handleImageUpload}
              disabled={isUploading}
              ref={fileInputRef}
            />
          </>
        )}

        {isUploading && <UploadingText>Uploading...</UploadingText>}
      </UploadBox>

      {uploadedImageUrl && (
        <DenoisingStrengthSlider
          denoisingStrength={denoisingStrength}
          setDenoisingStrength={setDenoisingStrength}
          isCreatingVariations={isCreatingVariations}
        />
      )}
    </UploadContainer>
  );
};

export default UploadReferenceImage;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  width: 100%;
`;

const ContainerWhenImageUploaded = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  width: 100%;
`;

const UploadBox = styled.div`
  position: relative; // this makes sure the X is in the right place
  background-color: none;
  color: ${PRIMARY_TEXT_COLOR};
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #555;
  transition: border-color 0.3s;

  width: 80%;

  &:hover {
    border-color: #777;
  }
`;

const UploadText = styled.p`
  margin-top: 10px;
  font-size: 16px;
`;

const UploadInstructions = styled.p`
  margin-top: 5px;
  font-size: 12px;
  color: ${SECONDARY_TEXT_COLOR};
`;

const HiddenInput = styled.input`
  display: none;
`;

const UploadingText = styled.p`
  color: ${PRIMARY_TEXT_COLOR};
  margin-top: 10px;
`;

const ImagePreview = styled.img`
  max-width: 200px;
  max-height: 200px;
  border-radius: 5px;

  margin-bottom: 10px;
`;

// simple "X" button to remove the uploaded image
const RemoveUploadedImage = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  background-color: transparent;
  color: ${PRIMARY_TEXT_COLOR};
  cursor: pointer;
  font-size: 20px;
  transition: color 0.3s;

  &:hover {
    color: #777;
  }
`;
