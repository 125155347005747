import styled from "styled-components";
import {
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../constants";
import { desktopMediaQuery, isSafari } from "../../styleHelpers";

export const StyledInputSmall = styled.input`
  width: 100%;
  border: none;
  padding: 8px;
  font-size: 14px;
  border: 1.5px solid ${BORDER_COLOR};
  border-radius: 8px;
  font-family: "Poppins", "Open sans", sans-serif;
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: 400;
  background-color: transparent;
  &:focus {
    outline: none;
  }

  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }

  // if isSafari is true, ifSafari is a boolean value
  ${isSafari &&
  `
    font-size: 16px;
  `}
`;
