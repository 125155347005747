import styled from 'styled-components';
import { StyledButton } from '../../components/common/StyledButton';

import {
  RiEyeCloseLine,
  RiEyeLine,
  RiLockUnlockLine,
  RiUserLine,
} from 'react-icons/ri';
import { LoginDetails } from '../../types';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  MAX_WIDTH,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../constants';
import { useState } from 'react';
import { desktopMediaQuery } from '../../styleHelpers';
import { FaSignInAlt } from 'react-icons/fa';
import { FaUserPlus } from 'react-icons/fa6';

export type Variant = 'login' | 'register';
interface P {
  variant: Variant;
  handleSend: (loginDetails: LoginDetails) => void;
  username: string;
  setUsername: (username: string) => void;
  password: string;
  setPassword: (password: string) => void;

  isLoadingLoggingIn: boolean;
}

const LoginOrRegisterForm = ({
  variant,
  handleSend,
  username,
  setUsername,
  password,
  setPassword,

  isLoadingLoggingIn,
}: P) => {
  const [eyeIcon, setEyeIcon] = useState<'open' | 'closed'>('closed');
  const handleClickOnEye = () => {
    const passwordField = document.getElementById(
      'password',
    ) as HTMLInputElement;
    if (passwordField.type === 'password') {
      passwordField.type = 'text';
    } else {
      passwordField.type = 'password';
    }
    setEyeIcon(eyeIcon === 'closed' ? 'open' : 'closed');
  };

  const EYE_ZINE = 17;
  return (
    <FormContainer>
      <TitleText>
        {variant === 'login' ? 'Welcome back!' : 'Create an Account'}
      </TitleText>
      <IconAndInputContainer>
        <RiUserLine size={32} />
        <SingleLineInputField
          placeholder="Username or Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </IconAndInputContainer>
      <IconAndInputContainer
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSend({ username, password });
          }
        }}
      >
        <RiLockUnlockLine size={32} />
        <SingleLineInputField
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          id="password"
        />
        <EyeIconContainer>
          {eyeIcon === 'closed' ? (
            <RiEyeCloseLine size={EYE_ZINE} onClick={handleClickOnEye} />
          ) : (
            <RiEyeLine size={EYE_ZINE} onClick={handleClickOnEye} />
          )}
        </EyeIconContainer>
      </IconAndInputContainer>
      {isLoadingLoggingIn ? (
        <LoadingIndicator differentBgColor />
      ) : (
        <SendButton
          variant={variant}
          onClick={() => handleSend({ username, password })}
        />
      )}
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 107%;
`;

const TitleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 24px;
  font-weight: 600;
  color: ${PRIMARY_TEXT_COLOR};
  margin-bottom: 16px;
`;

const SingleLineInputField = styled.input`
  border: none;
  width: 100%;
  border-radius: 24px;
  padding: 14px;
  padding-bottom: 10px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;
  word-break: break-word;
  &:focus {
    outline: none;
  }
  :-webkit-autofill {
    filter: none;
    box-shadow: 0 0 0 40px ${BACKGROUND_COLOR} inset;
    -webkit-text-fill-color: ${PRIMARY_TEXT_COLOR} !important;
  }
  resize: none;
`;

const EyeIconContainer = styled.div`
  margin-top: 4px;
  margin-right: -23px;
  margin-left: -6px;
`;

const IconAndInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  border-radius: 4px;
  border: 1.5px solid ${BORDER_COLOR};
  background-color: transparent;
  padding-left: 4px;

  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }
`;

interface BP {
  variant: Variant;
  onClick: () => void;
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const SendButton = ({ variant, onClick }: BP) => (
  <StyledButton onClick={onClick}>
    <ButtonContainer>
      {variant === 'login' ? (
        <>
          <FaSignInAlt /> Login
        </>
      ) : (
        <>
          <FaUserPlus />
          Sign Up
        </>
      )}
    </ButtonContainer>
  </StyledButton>
);

export default LoginOrRegisterForm;
