import { getBaseUrlForFetching } from '../../App';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { useLoggedInUserContext } from '../../context/LoggedInUserContextProvider';
import { LoggedInUser } from '../../types';
import {
  trackGoldUserInfoFetched,
  trackUserDataHasBeenFetched,
  trackUserWithMoreThan100CreditsFetched,
} from '../../utils/analytics';
import userSettingsInStorage from '../../localStorage/userSettingsInStorage';
import { headers } from '../helpers';
import useSetUserCookie from './useSetUserCookie';

interface ResultReturn {
  data: {
    user: LoggedInUser;
    token?: string;
  };
}

/**
 * This hook is used to get the user from the server
 *
 * Also sets the user and saved images in the context
 */
const useHandleGetUser = () => {
  const { setLoggedInUser } = useLoggedInUserContext();
  const { setLoading, setShowConfirmEmailModal } = useGeneralContext();

  const setUserCookie = useSetUserCookie();

  const { setSpeedMode } = useImageGenerationSettingsContext();

  const handleCheckingForNewAuthMethod = async (user: LoggedInUser) => {
    if (!user.verifiedEmail) {
      setShowConfirmEmailModal(true);
    }
  };

  const handleGetUser = async (token: string) => {
    // if in root, return early (this is the landing page, not needed to login yet)
    if (window.location.pathname === '/') {
      return;
    }
    if (!token) {
      return;
    }
    setLoading(true);
    const functionName = 'getUser';
    const baseUrl = getBaseUrlForFetching();
    const getUserUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(getUserUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify({ token }),
    });

    if (response.status === 200) {
      const result: ResultReturn = await response.json();
      const data = result.data;

      // if result.token exists, it means the user type was changed after last login and cookie needs to be updated
      if (data.token) {
        setUserCookie(data.token);
      }

      const user = data.user;
      if (user?.type === 'gold') {
        userSettingsInStorage().speedMode.set('turbo');
        setSpeedMode('turbo');
        trackGoldUserInfoFetched();
      }

      if (user?.credits && user.credits > 100) {
        trackUserWithMoreThan100CreditsFetched();
      }

      const userSettingInStorage = userSettingsInStorage().speedMode.get();

      const isUserSettingNormal = userSettingInStorage === 'normal';
      const isUserSettingFast = userSettingInStorage === 'fast';
      const isFastOrNormal = isUserSettingNormal || isUserSettingFast;

      if (user?.credits && user.credits > 2 && !isFastOrNormal) {
        setSpeedMode('turbo');
      }

      trackUserDataHasBeenFetched();

      handleCheckingForNewAuthMethod(user);

      setLoggedInUser(user);
    }
    setLoading(false);
  };

  return handleGetUser;
};

export default useHandleGetUser;
