import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useHandleGetUser from '../../hooks/userHandling/useHandleGetUser';
import useHandleLogin from '../../hooks/userHandling/useHandleLogin';
import useHandleRegister from '../../hooks/userHandling/useHandleRegister';
import { GenericSetState, LoginDetails } from '../../types';
import LoginOrRegisterForm, { Variant } from './LoginOrRegisterForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_PATH, MAGIC_PATH } from '../../pathNames';
import { desktopMediaQuery } from '../../styleHelpers';

// TODO: automatically show register if coming fromm the modal
const LoginOrRegisterPage = () => {
  const [formType, setFormType] = useState<Variant>('login');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const handleLogin = useHandleLogin();
  const handleRegister = useHandleRegister();

  const [isLoadingLoggingIn, setIsLoadingLoggingIn] = useState(false);

  const navigate = useNavigate();

  const handleGetUser = useHandleGetUser();

  const location = useLocation();

  useEffect(() => {
    // Check the pathname to determine the form type
    const path = location.pathname;
    if (path.includes('/signup')) {
      setFormType('register');
    } else {
      setFormType('login');
    }
  }, [location.pathname]);

  // TODO: refactor
  const handleSend = async (loginDetails: LoginDetails) => {
    setIsLoadingLoggingIn(true);
    if (!loginDetails.username) {
      alert('Username is required');
      setIsLoadingLoggingIn(false);
      return;
    }

    if (!loginDetails.password) {
      alert('Password is required');
      setIsLoadingLoggingIn(false);
      return;
    }

    if (formType === 'login') {
      const result = await handleLogin(loginDetails);
      setIsLoadingLoggingIn(false);

      if (result.status === 401) {
        alert('Incorrect username or password');
        return;
      }

      if (result.status === 200) {
        setUsername('');
        setPassword('');

        handleGetUser(result.token as string);
        navigate(HOME_PATH);
        return;
      }
    }

    if (formType === 'register') {
      if (loginDetails.username.length <= 4) {
        alert('Username must more than 4 characters long');
        setIsLoadingLoggingIn(false);
        return;
      }

      if (loginDetails.password.length <= 5) {
        alert('Password must at least 5 characters long');
        setIsLoadingLoggingIn(false);
        return;
      }
      const result = await handleRegister(loginDetails);
      setIsLoadingLoggingIn(false);

      if (result.status === 409) {
        alert('Username already taken');
        return;
      }

      if (result.status === 200) {
        setUsername('');
        setPassword('');
        handleGetUser(result.token as string);

        if (location.state && location.state.redirectTo === 'magic') {
          navigate(MAGIC_PATH);
        } else {
          navigate(HOME_PATH);
        }
        return;
      }
    }
  };

  return (
    <LoginRegisterPageContainer>
      <LoginOrRegisterForm
        variant={formType}
        handleSend={handleSend}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        isLoadingLoggingIn={isLoadingLoggingIn}
      />
      {formType === 'login' ? (
        <ShowRegisterButton setVariant={setFormType} />
      ) : (
        <ShowLoginButton setVariant={setFormType} />
      )}
    </LoginRegisterPageContainer>
  );
};

type ButtonProps = {
  setVariant: GenericSetState<Variant>;
};

const ShowRegisterButton = ({ setVariant }: ButtonProps) => {
  return (
    <TextButton onClick={() => setVariant('register')}>
      Don't have an account? Register
    </TextButton>
  );
};

const ShowLoginButton = ({ setVariant }: ButtonProps) => {
  return (
    <TextButton onClick={() => setVariant('login')}>
      Already have an account? Login
    </TextButton>
  );
};
const LoginRegisterPageContainer = styled.div`
  position: absolute;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${desktopMediaQuery} {
    top: 32%;
    transform: translateY(-32%);
    left: 54%;
    transform: translateX(-54%);
  }
`;

const TextButton = styled.button`
  background: none;
  border: none;
  color: #1da1f2;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-top: 12px;
`;

export default LoginOrRegisterPage;
