const TermsOfService = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>
        <strong>Effective Date:</strong> 16 May 2024
      </p>

      <p>
        Welcome to Picarus AI. By accessing and using our AI image generation
        services, you agree to comply with and be bound by the following terms
        and conditions.
      </p>

      <h2>1. Company Information</h2>
      <p>AP Software Ltd, Finland</p>

      <h2>2. Service Description</h2>
      <p>
        We provide AI image generation services that allow users to create and
        customize images using our AI technology.
      </p>

      <h2>3. User Responsibilities</h2>
      <p>
        Users must provide accurate and current information when creating an
        account.
      </p>
      <p>
        Users are responsible for maintaining the confidentiality of their
        account information.
      </p>
      <p>Users agree to use our services in a lawful manner.</p>

      <h2>4. Prohibited Activities</h2>
      <p>Users are prohibited from:</p>
      <ul>
        <li>Creating adult, age-restricted, sexually-orientated, pornographic content or any material of a lewd and lascivious nature is strictly prohibited</li>
        <li>Engaging in any illegal activities.</li>
        <li>
          Using the service to create or distribute unlawful, harmful, or
          offensive content.
        </li>
        <li>
          Attempting to interfere with the proper functioning of the service.
        </li>
        <li>
          Misrepresenting your identity or affiliation with any person or
          entity.
        </li>
      </ul>

      <h2>5. Account Information</h2>
      <p>Users must be at least 13 years old to create an account.</p>
      <p>
        Each user is responsible for all activities conducted through their
        account.
      </p>
      <p>
        Users must notify us immediately if they suspect unauthorized use of
        their account.
      </p>

      <h2>6. Intellectual Property</h2>
      <p>
        All content generated by our AI remains the intellectual property of AP
        Software Ltd.
      </p>
      <p>
        Users are granted a license to use the generated content for any legal
        purposes.
      </p>
      <p>
        AP Software Ltd reserves the right to use any generated content for
        marketing and promotional purposes.
      </p>

      <h2>7. Dispute Resolution</h2>
      <p>
        Any disputes arising from these terms or the use of our services will be
        resolved in accordance with the laws of Finland.
      </p>

      <h2>8. Termination Clause</h2>
      <p>
        AP Software Ltd reserves the right to terminate or suspend a user's
        access to the service at any time, without notice, for conduct that we
        believe violates these Terms of Service or is harmful to other users of
        the service.
      </p>

      <h2>9. Changes to Terms</h2>
      <p>
        We may update these Terms of Service from time to time. We will notify
        users of any changes by posting the new terms on our website. Continued
        use of the service after any changes implies acceptance of the new
        terms.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        These Terms of Service are governed by and construed in accordance with
        the laws of Finland.
      </p>
    </div>
  );
};

export default TermsOfService;
