const RefundPolicy = () => {
  return (
    <div>
      <h1>Refund Policy</h1>
      <p><strong>Effective Date:</strong> 16 May 2024</p>

      <p>At Picarus AI, a service of AP Software Ltd, we strive to ensure your satisfaction with our AI image generation services. However, if you are not completely satisfied with your purchase, please review our refund policy below.</p>

      <h2>1. Eligibility for Refunds</h2>
      <p>Refunds are available under the following conditions:</p>
      <ul>
        <li>You must request a refund within 30 days of your purchase.</li>
        <li>The service or product must be faulty, not as described, or not fit for purpose.</li>
      </ul>

      <h2>2. Non-Refundable Items</h2>
      <p>The following items are non-refundable:</p>
      <ul>
        <li>Services that have been fully delivered or consumed.</li>
        <li>Promotional or discounted services.</li>
      </ul>

      <h2>3. How to Request a Refund</h2>
      <p>To request a refund, please contact our customer support team at hello@picarus.ai with your order details and the reason for your request. We will review your request and notify you of the approval or rejection of your refund.</p>

      <h2>4. Processing Refunds</h2>
      <p>If your refund is approved, it will be processed, and a credit will automatically be applied to your original method of payment within 7-10 business days. Please note that it may take additional time for your bank or credit card company to process and post the refund.</p>

      <h2>5. Changes to This Refund Policy</h2>
      <p>We may update this Refund Policy from time to time. We will notify you of any changes by posting the new policy on our website. Continued use of the service after any changes implies acceptance of the new Refund Policy.</p>

      <h2>6. Contact Us</h2>
      <p>If you have any questions or concerns about this Refund Policy, please contact us at hello@picarus.ai.</p>
    </div>
  );
}

export default RefundPolicy;
