import React from 'react';
import { MdMoreHoriz } from 'react-icons/md';
import { StyleCard, StyleName } from './StyleCardParts';
import styled from 'styled-components';
import { StyleInterface } from '../../../types';
import { getPreviewStyles } from '../../../utils/imageStylesHelpers';

interface P {
  onClick: () => void;
  selectedStyle: StyleInterface;
}

const ShowMoreButton: React.FC<P> = ({ onClick, selectedStyle }) => {
  const imageToShow =
    'https://onlyfakes-images-sdapi.s3.eu-north-1.amazonaws.com/generations/506418c0-6af7-468a-99a8-42c72398776d.jpeg';

  const previewStyles = getPreviewStyles();
  
  const isSelectedStyleInPreviewStyles = previewStyles.some(
    (style) => style.id === selectedStyle.id,
  );

  const showThisButtonAsSelected = !isSelectedStyleInPreviewStyles;
  return (
    <Container>
      <StyleCard 
      style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imageToShow})` }}
      onClick={onClick} isSelected={showThisButtonAsSelected}>
        <InnerContainer>
          <PlusSign />
          <StyleName>
          Menu
          </StyleName>
        </InnerContainer>
      </StyleCard>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
`;

const InnerContainer = styled.div`
  display: flex;
`;

const PlusSign = styled(MdMoreHoriz)`
  font-size: 40px;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default ShowMoreButton;
