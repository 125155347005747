import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { SavedImage } from "../../types";
import { headers } from "../helpers";
import { useImagesContext } from "../../context/ImageContextProvider";

const useGetSavedImagesWithToken = () => {
  const [{ token }] = useCookies(["token"]);

  const { setHasFetchedSavedImages } = useImagesContext();

  const sortSavedImages = (savedImages: SavedImage[]) =>
    savedImages.sort((a, b) => {
      if (!a.savedDate || !b.savedDate) return 0;
      return new Date(b.savedDate).getTime() - new Date(a.savedDate).getTime();
    });

  const getSavedImagesWithToken = async (): Promise<SavedImage[]> => {
    const functionName = "getSavedImagesWithToken";
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ token }),
      headers,
    });

    if (response.status === 400) {
      return [];
    }

    if (response.status !== 200) {
      throw new Error("failed to fetch");
    }

    const { images } = await response.json();

    const sortedBody = sortSavedImages(images);

    setHasFetchedSavedImages(true);

    return sortedBody;
  };
  return getSavedImagesWithToken;
};

export default useGetSavedImagesWithToken;
