import { getBaseUrlForFetching } from "../../App";
import { useImagesContext } from "../../context/ImageContextProvider";
import { Category } from "../../pages/FeedPage/EngineFilterDropdown";
import { SharedImage } from "../../types";

export type SortByType =
  | "Latest"
  | "Best today"
  | "Best past week"
  | "Best past month"
  | "Best all time";

const useGetFeed = () => {
  const headers = {
    "Content-type": "Application/json",
  };
  const baseUrl = getBaseUrlForFetching();
  const {
    latestFeedImages,
    setLatestFeedImages,
    fetchedLastTime,
    setFetchedLastTime,
    lastCategory,
    setLastCategory,
    lastSortBy,
    setLastSortBy,

    latestOrBestLastTime,
    setLatestOrBestLastTime,
  } = useImagesContext();

  // fetches images every 60 seconds
  // if less than 60 images passed since last time, use the ones in context
  const HOW_OFTEN_TO_FETCH = 60;

  const getLatestShared = async (
    from: number,
    to: number,
    category: string
  ): Promise<SharedImage[]> => {
    const calculateShouldUseContextImages = () => {
      if (lastCategory !== category) {
        return false;
      }
      if (latestOrBestLastTime !== "latest") {
        return false;
      }
      const now = Date.now();
      const timeSinceLastFetch = (now - fetchedLastTime) / 1000;
      return timeSinceLastFetch < HOW_OFTEN_TO_FETCH;
    };

    const shouldUseContextImages = calculateShouldUseContextImages();

    if (shouldUseContextImages) {
      return latestFeedImages;
    }

    const functionName = "getLatestShared";

    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ from, to, category }),
      headers,
    });

    if (response.status !== 200) {
      throw new Error("failed to fetch");
    }

    const data = await response.json();

    const images = data.body;
    setLatestFeedImages(images);
    setFetchedLastTime(Date.now());
    setLastCategory(category);
    setLatestOrBestLastTime("latest");
    return images;
  };

  const getBestShared = async (
    from: number,
    to: number,
    category: Category,
    sortBy: SortByType,
    isEngineFilter: boolean
  ): Promise<SharedImage[]> => {
    const calculateShouldUseContextImages = () => {
      if (lastCategory !== category || lastSortBy !== sortBy) {
        return false;
      }
      if (latestOrBestLastTime !== "best") {
        return false;
      }
      const now = Date.now();
      const timeSinceLastFetch = (now - fetchedLastTime) / 1000;
      return timeSinceLastFetch < HOW_OFTEN_TO_FETCH;
    };

    const shouldUseContextImages = calculateShouldUseContextImages();

    if (shouldUseContextImages) {
      return latestFeedImages;
    }

    const functionName = "getBestShared";

    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ from, to, category, sortBy, isEngineFilter }),
      headers,
    });

    if (response.status !== 200) {
      throw new Error("failed to fetch");
    }

    const data = await response.json();
    const images = data.body;
    setLatestFeedImages(images);
    setFetchedLastTime(Date.now());
    setLastCategory(category);
    setLastSortBy(sortBy);
    setLatestOrBestLastTime("best");
    return images;
  };

  const getBestForLandingPage = async (): Promise<string[]> => {
    const functionName = "getBestImagesForLandingPage";

    const fetchUrl = `${baseUrl}/${functionName}`;

    const response = await fetch(fetchUrl, {
      method: "GET",
      headers,
    });

    if (response.status !== 200) {
      throw new Error("failed to fetch");
    }

    const imageUrls = await response.json();
    return imageUrls;
  };

  return { getLatestShared, getBestShared, getBestForLandingPage };
};

export default useGetFeed;
