import React from "react";
import styled from "styled-components";
import { BORDER_COLOR, PRIMARY_TEXT_COLOR } from "../../constants";
import { desktopMediaQuery } from "../../styleHelpers";

const InstructionsText: React.FC = () => {
  return (
    <Container>
      <Title>Get started:</Title>
      🎨 Pick a Style <br />
      💬 Type your idea
      <br />✨ Hit Generate
      <br />🔍 Check Explore
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;

  padding: 8px;

  color: ${PRIMARY_TEXT_COLOR};

  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 0.5px solid ${BORDER_COLOR};

  border-radius: 10px;

  ${desktopMediaQuery} {
    padding: 16px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
`;

export default InstructionsText;
