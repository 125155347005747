import styled from "styled-components";
import StyledSelect from "../../components/common/StyledSelect";
import { getEnginesByType } from "../../utils/engineHelpers";
import { EngineType } from "../../types";

type P = {
  value: string;
  setValue: (value: string) => void;
  inLibrary?: boolean;
};

export const ENGINE_CATEGORIES = ["All", "Realistic", "Anime", "Digital"];
export type Category = (typeof ENGINE_CATEGORIES)[number];

const realisticEngines = getEnginesByType("realistic");
const animeEngines = getEnginesByType("anime");
const digitalEngines = getEnginesByType("digital");

const EngineFilterDropdown = ({ value, setValue, inLibrary }: P) => {
  const handleChange = (e: any) => {
    const newCategory = e.target.value;
    if (typeof newCategory !== "string") {
      console.error("value is not a string");
      return;
    }

    setValue(newCategory);
  };
  const labelText = inLibrary ? "Filter by engine" : "Engine";

  return (
    <Container>
      <Label>{labelText}</Label>
      <SmallerSelect
        value={value}
        onChange={(e) => handleChange(e)}
        inLibrary={inLibrary}
      >
        <option value="All">All engines</option>
        <option value="Realistic">All Realistic engines</option>
        <option value="Anime">All Anime engines</option>
        <option value="Digital">All Digital engines</option>
        <optgroup label="Realistic">
          <Options options={realisticEngines} />
        </optgroup>
        <optgroup label="Anime">
          <Options options={animeEngines} />
        </optgroup>
        <optgroup label="Digital">
          <Options options={digitalEngines} />
        </optgroup>
      </SmallerSelect>
    </Container>
  );
};

interface OptionsProps {
  options: EngineType[];
}
const Options = ({ options }: OptionsProps) => (
  <>
    {options.map((option) => (
      <option key={option.modelId} value={option.modelId}>
        {option.name}
      </option>
    ))}
  </>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  align-self: flex-start;
`;

const SmallerSelect = styled(StyledSelect)<{ inLibrary?: boolean }>`
  width: ${({ inLibrary }) => (inLibrary ? "230px" : "130px")};
`;

const Label = styled.div``;

export default EngineFilterDropdown;
