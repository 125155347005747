import { LibraryTabName, SpeedModeType } from "../types";

interface UserSettings {
  advancedMode: boolean;
  activeTabInLibrary: LibraryTabName;
  speedMode: SpeedModeType;
}

/**
 * Note: these docs might be missing some properties, it's just for reference.
 *
 * Provides a local storage-based management system for user settings.
 * This system allows for the retrieval and modification of user settings such as
 * 'advancedMode' (a boolean) and 'activeTabInLibrary' (of type LibraryTabName).
 *
 * The settings are stored in the local storage under a single key ('userSettings')
 * in JSON format. If the settings are not already initialized in the local storage,
 * default values are used (advancedMode: false, activeTabInLibrary: 'history').
 *
 * The module offers initially two main properties with 'get' and 'set' functions:
 * - advancedSettings: Handles the 'advancedMode' setting.
 *    - get(): Returns the current 'advancedMode' value (boolean).
 *    - set(value: boolean): Sets the 'advancedMode' value and updates local storage.
 *
 * - activeTabInLibrary: Handles the 'activeTabInLibrary' setting.
 *    - get(): Returns the current 'activeTabInLibrary' value (LibraryTabName).
 *    - set(value: LibraryTabName): Sets the 'activeTabInLibrary' value and updates local storage.
 *
 * @returns An object containing the 'advancedSettings' and 'activeTabInLibrary' properties, each with 'get' and 'set' methods.
 */
const userSettingsInStorage = () => {
  const nameInLocalStorage = "userSettings";
  let cachedSettings: UserSettings | undefined;

  const getSettings = (): UserSettings => {
    if (!cachedSettings) {
      const storageContents = localStorage.getItem(nameInLocalStorage);
      cachedSettings = storageContents
        ? JSON.parse(storageContents)
        : {
            advancedMode: false,
            activeTabInLibrary: "history",
            speedMode: "turbo",
            activeTabInImageGenerationPage: "magic-mode",
          };
    }
    return cachedSettings as UserSettings;
  };

  const saveSettings = (settings: UserSettings): void => {
    try {
      localStorage.setItem(nameInLocalStorage, JSON.stringify(settings));
      cachedSettings = settings;
    } catch (error) {
      console.error("Error saving user settings:", error);
    }
  };

  const advancedSettings = {
    get: (): boolean => getSettings().advancedMode,
    set: (value: boolean): void => {
      const settings = getSettings();
      saveSettings({ ...settings, advancedMode: value });
    },
  };

  const activeTabInLibrary = {
    get: (): LibraryTabName => getSettings().activeTabInLibrary,
    set: (value: LibraryTabName): void => {
      const settings = getSettings();
      saveSettings({ ...settings, activeTabInLibrary: value });
    },
  };

  const speedMode = {
    get: (): SpeedModeType => getSettings().speedMode,
    set: (value: SpeedModeType): void => {
      const settings = getSettings();
      saveSettings({ ...settings, speedMode: value });
    },
  };

  return {
    advancedSettings,
    activeTabInLibrary,
    speedMode,
  };
};

export default userSettingsInStorage;
