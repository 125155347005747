import styled from "styled-components";
import { LIGHT_GREY_COLOR } from "../../constants";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import { LoggedInUser } from "../../types";

const ProfileTopBar = () => {
  const { loggedInUser } = useLoggedInUserContext();
  if (!loggedInUser) return null;

  return (
    <Container>
      <ProfileImage url={loggedInUser?.profileImageUrl} />
      <ProfileInfo user={loggedInUser} />
    </Container>
  );
};

const ProfileImage = ({ url = DEFAULT_PROFILE_PAGE_URL }: ImgProps) => {
  return (
    <ImageContainer>
      <Image src={url} />
    </ImageContainer>
  );
};

const ProfileInfo = ({ user }: InfoProps) => {
  return <InfoContainer>You are logged in as: {user.username}</InfoContainer>;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  padding-bottom: 32px;
  border-bottom: 2px solid ${LIGHT_GREY_COLOR};
`;

interface ImgProps {
  url?: string;
}
const DEFAULT_PROFILE_PAGE_URL =
  "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";

const ImageContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface InfoProps {
  user: LoggedInUser;
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
`;

export default ProfileTopBar;
