import React from "react";
import { MdOutlineLayers } from "react-icons/md";
import {
  ContainerForSelect,
  StyledSelectForBelowImage,
} from "./SharedComponentsInBelowImage";

interface AmountToGenerateSelectorProps {
  amountToGenerate: number;
  setAmountToGenerate: (amount: number) => void;
}

const AmountToGenerateSelector: React.FC<AmountToGenerateSelectorProps> = ({
  amountToGenerate,
  setAmountToGenerate,
}) => {
  const handleChange = (e: any) => {
    setAmountToGenerate(parseInt(e.target.value));
  };

  return (
    <ContainerForSelect>
      <MdOutlineLayers size={20} />
      <StyledSelectForBelowImage
        value={amountToGenerate}
        onChange={handleChange}
      >
        <option value={1}>1x</option>
        <option value={2}>2x</option>
        <option value={3}>3x</option>
        <option value={4}>4x</option>
        <option value={5}>5x</option>
        <option value={6}>6x</option>
        <option value={7}>7x</option>
        <option value={8}>8x</option>
      </StyledSelectForBelowImage>
    </ContainerForSelect>
  );
};

export default AmountToGenerateSelector;
