import styled from "styled-components";
//@ts-ignore
import { ReactComponent as Logo } from "./Picarus_logo.svg"

interface P {
  bigger?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PicarusLogo = ({ bigger }: P) => <StyledLogo />;

const StyledLogo = styled(Logo)`
  width: 100px;
  height: 40px;
`;

export default PicarusLogo;
