import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { SharedImage } from "../../types";
import FeedImage from "./FeedImage";
import FeedModal from "./FeedModal";
import { getIsOnDesktop } from "../../styleHelpers";
import useImageFromParams from "./useImageFromParams";
import useUpdateAmountOfRemixes from "../../hooks/feed/useUpdateAmountOfRemixes";
import { trackFirstTimeUserOpensAnImage, trackUserOpensAnImage } from "../../utils/analytics";
import { useGeneralContext } from "../../context/GeneralContextProvider";

interface I {
  images: SharedImage[];
}

const NoImgs = () => <h3>No images... Try refreshing</h3>;

const FeedContainer = ({ images }: I) => {
  console.log(images)
  const [items, setItems] = useState<SharedImage[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<SharedImage | null>(null);
  const [indexOfSelectedImage, setIndexOfSelectedImage] = useState<number>(0);
  const { isFirstTimeUser  } = useGeneralContext();

  const updateLikes = useUpdateAmountOfRemixes();

  useImageFromParams(setSelectedImage, setIsModalOpen);

  const SHOW_X_PER = 10;

  useEffect(() => {
    setItems(images.slice(0, SHOW_X_PER));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const fetchMore = () => {
    const nextItems = images.slice(items.length, items.length + SHOW_X_PER);

    setItems(items.concat(nextItems));
  };
  const hasMore = items.length < images.length;
  const dataLength = items?.length;

  const handleSelectImage = (image: SharedImage) => {
    setSelectedImage(image);
    // TODO: refactor app/feed to be base url
    // TODO: refactor to be its own function
    window.history.replaceState(null, "", `/app/feed?id=${image.id}`);
  };

  const isOnDesktop = getIsOnDesktop();

  const containerRef = useRef<HTMLDivElement>(null);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  // this is just a hack to make sure it scrolls back to correct place in feed when saved
  // also updates points of an image
  const handleSaveIsClicked = () => {
    selectedImage && updateLikes(selectedImage);
    let scrolledAmount = 0;
    const scrollThismuchAtOnce = isOnDesktop ? 1250 : 2500;
    const overScrollThisMuch = 1500;
    const waitBetweenScrolls = 150;

    const interval = setInterval(() => {
      scrolledAmount += scrollThismuchAtOnce;
      document.documentElement.scrollTop = scrolledAmount;

      if (scrolledAmount >= currentScrollPosition + overScrollThisMuch) {
        document.documentElement.scrollTop = currentScrollPosition;
        clearInterval(interval);
      }
    }, waitBetweenScrolls);
  };

  const handleClickOnImage = (image: SharedImage, i: number) => {
    trackUserOpensAnImage();
    if (isFirstTimeUser) {
      trackFirstTimeUserOpensAnImage();
    }

    handleSelectImage(image);
    setIndexOfSelectedImage(i);
    setIsModalOpen(true);

    // save current scroll position so we can scroll back to it when closing modal
    const scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;
    setCurrentScrollPosition(scrollPosition);
  };

  const scrollAmountWhenShowingNextOrPreviousImage = isOnDesktop ? 72.5 : 145;

  const handleShowPreviousImage = () => {
    if (indexOfSelectedImage === 0) return;
    const previousImage = images[indexOfSelectedImage - 1];
    handleSelectImage(previousImage);
    setIndexOfSelectedImage(indexOfSelectedImage - 1);

    // scroll up when showing previous image, so when closing modal, the user is at the same position
    window.scrollTo(
      0,
      window.scrollY - scrollAmountWhenShowingNextOrPreviousImage
    );
  };

  const handleShowNextImage = () => {
    if (indexOfSelectedImage === images.length - 1) return;
    const nextImage = images[indexOfSelectedImage + 1];
    handleSelectImage(nextImage);
    setIndexOfSelectedImage(indexOfSelectedImage + 1);

    // scroll down when showing next image, so when closing modal, the user is at the same position
    window.scrollTo(
      0,
      window.scrollY + scrollAmountWhenShowingNextOrPreviousImage
    );
  };

  return (
    <Container ref={containerRef} id="scrollableDiv">
      <FeedModal
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        image={selectedImage}
        handleShowPreviousImage={handleShowPreviousImage}
        handleShowNextImage={handleShowNextImage}
        handleSaveIsClicked={handleSaveIsClicked}
      />
      {images.length === 0 ? (
        <NoImgs />
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchMore}
          hasMore={hasMore}
          loader={<NoImgs />}
          scrollThreshold={0.8}
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: isOnDesktop
              ? "repeat(4, 2fr)"
              : "repeat(2, 1fr)",
            gridTemplateRows: isOnDesktop ? "repeat(4, 2fr)" : "repeat(2, 1fr)",
            gridGap: "1px",
            overflow: "hidden",
          }}
        >
          {items?.map((image, i) => (
            <div onClick={() => handleClickOnImage(image, i)}>
              <FeedImage key={image.id} image={image} />
            </div>
          ))}
        </InfiniteScroll>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

export default FeedContainer;
