import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

export const StyledButton = styled.button<{ inLandingPage?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  padding: 10px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  border: 1px solid ${PRIMARY_COLOR};
  color: ${PRIMARY_COLOR};
  margin-top: 10px;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      transform: translateY(-2px);
      transition: transform 0.2s;
    }
  }
`;
