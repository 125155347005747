import styled from 'styled-components';
import { DESKTOP_WIDTH, PRIMARY_COLOR } from '../../constants';

const Footer = () => {
  const showTermsAndService = () => {
    window.open('/terms-of-service', '_blank');
  };

  const showPrivacyPolicy = () => {
    window.open('/privacy-notice', '_blank');
  };

  const showRefundPolicy = () => {
    window.open('/refund-policy', '_blank');
  };
  return (
    <Wrapper>
      <SupportEmail>
        <span>📧 hello@picarus.ai</span>
      </SupportEmail>
      <TermsAndService onClick={showTermsAndService}>
        Terms of Service
      </TermsAndService>

      <TermsAndService onClick={showPrivacyPolicy}>
        Privacy Notice
      </TermsAndService>

      <TermsAndService onClick={showRefundPolicy}>
        Refund Policy
      </TermsAndService>
      <Copyright>
        © 2023 Picarus <br />
        All rights reserved
        <br />
      </Copyright>
    </Wrapper>
  );
};
/*
      <ParterSiteLink href="https://ainudes.io/" target="_blank">
        <span>Visit partner site</span>
      </ParterSiteLink>
      */

export default Footer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-bottom: 16px;
  font-size: 12px;
  @media (max-width: ${DESKTOP_WIDTH}px) {
    padding-bottom: 100px;
  }
`;

const Copyright = styled.div``;

const TermsAndService = styled.a`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SupportEmail = styled.div``;
