import styled from 'styled-components';
import { BACKGROUND_COLOR, PRIMARY_COLOR } from '../../constants';
import { GenericSetState } from '../../types';
import { StyledButton } from '../common/StyledButton';
import TransitioningModal from './TransitioningModal';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
}

const SomethingWentWrongModal = ({ showModal, setShowModal }: I) => {
  const { uploadedImageUrl } = useImageGenerationSettingsContext();
  const handleClose = () => {
    setShowModal(false);
  };

  const textToShow = !!uploadedImageUrl
    ? "Oops! Something went wrong 😔. It might be due to the image you uploaded. Please try a different image or proceed without one. We're aware of this bug and are working on it. Thanks for your patience! 🙇 PS. if you lost credits due to this, contact us providing username and how many credits you lost."
    : 'An error happened, try again! If this keeps happening, try another engine. If the issues still persist, please contact me at hello@picarus.ai. Sorry 🙇 PS. if you lost credits due to this, contact us providing username and how many credits you lost.';
  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          backgroundColor: BACKGROUND_COLOR,
          padding: '32px',
          width: '80%',
          maxWidth: '600px',
          margin: 'auto',
          marginTop: '20%',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      // click anywhere to close modal
      onRequestClose={handleClose}
    >
      <TitleText>Sorry, something went wrong 😭</TitleText>
      <ModalText>{textToShow}</ModalText>
      <AcceptButton onClick={handleClose}>OK!</AcceptButton>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 50px;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: #e8eaed;
  text-align: center;
  margin-bottom: 16px;
`;

const TitleText = styled(ModalText)`
  font-size: 24px;
`;

const AcceptButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;

export default SomethingWentWrongModal;
