import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { getBaseUrlForFetching } from "../../App";
import { headers } from "../helpers";
import { SavedImage } from "../../types";

const useFetchFolderImages = (folderId?: string) => {
  const [{ token }] = useCookies(["token"]);
  const [images, setImages] = useState<SavedImage[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      if (!token || !folderId) {
        setIsLoading(false);
        return;
      }

      const functionName = "fetchFolderImages";
      const baseUrl = getBaseUrlForFetching();
      const fetchUrl = `${baseUrl}/${functionName}`;

      try {
        const response = await fetch(fetchUrl, {
          method: "POST",
          body: JSON.stringify({ folderId, token }),
          headers,
        });

        if (response.status !== 200) {
          throw new Error(`Error fetching images for folder: ${folderId}`);
        }

        const body = await response.json();

        if (!body.images) {
          throw new Error(`No images found for folder: ${folderId}`);
        }

        const images = body.images as SavedImage[];


        setImages(images);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [folderId, token]);

  return { images, isLoading };
};

export default useFetchFolderImages;
