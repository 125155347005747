import React from "react";
import { useLoggedInUserContext } from "../../../context/LoggedInUserContextProvider";
import Cookies from "../../../utils/Cookies";
import styled from "styled-components";
import { MdOutlineLogin, MdOutlineLogout } from "react-icons/md";
import { toast } from "react-toastify";
import { PROFILE_PATH } from "../../../pathNames";
import { useNavigate } from "react-router-dom";
import { OptionContainer } from "./TopBarMenu";

interface P {
  closePopup: () => void;
}

export const LogoutOption: React.FC<P> = ({ closePopup }) => {
  const { setLoggedInUser } = useLoggedInUserContext();
  const onLogout = () => {
    setLoggedInUser(null);
    Cookies().clearToken();
    toast.success("Logged out successfully");
    closePopup();
  };
  return (
    <OptionContainer onClick={onLogout}>
      <Button>
        <MdOutlineLogout size={18} />
        Logout
      </Button>
    </OptionContainer>
  );
};

const Button = styled.button`
  background-color: transparent;
  align-items: center;
  display: flex;
  gap: 8px;
  font-size: 14px;

  border-radius: 8px;

  margin-left: 1px;

  border: none;
`;

export const LoginOption: React.FC<P> = ({ closePopup }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    closePopup();
    navigate(PROFILE_PATH);
  };
  return (
    <OptionContainer onClick={handleClick}>
      <Button>
        <MdOutlineLogin />
        Login / Register
      </Button>
    </OptionContainer>
  );
};

export default {};
