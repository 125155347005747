import { useNavigate } from "react-router";
import styled from "styled-components";
import DownloadIcon from "../../components/common/DownloadIcon";
import { IconButtonContainer } from "../../components/common/IconButtonStuff";
import RemoveButton from "../../components/common/RemoveButton";
import SaveButton from "../../components/common/SaveButton";
import { RemixIcon } from "../../components/common/SelectIcon";
import { useGeneralContext } from "../../context/GeneralContextProvider";
import { FakeImage, SharedImage } from "../../types";
import { SmoothlyLoadingImage } from "../../components/common/SmoothlyLoadingImage";
import { useState } from "react";
import { HOME_PATH } from "../../pathNames";
import { BiUpvote } from "react-icons/bi";
import CopyLinkToClipboardIcon from "../../components/common/CopyLinkToClipboardIcon";
import { useImageGenerationSettingsContext } from "../../context/ImageGenerationSettingsProvider";

interface SharedImageProps {
  sharedImage: SharedImage;
  handleRemove: (image: SharedImage) => void;
}

const SharedImageBig = ({ sharedImage, handleRemove }: SharedImageProps) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const { setActiveImage, setImages } = useGeneralContext();

  const { setUploadedImageUrl} = useImageGenerationSettingsContext();

  const handleGoToRemix = () => {
    setUploadedImageUrl("");
    const image: FakeImage = {
      ...sharedImage.image,
    };
    setImages((images) => images.concat(image));
    setActiveImage(image);
    navigate(HOME_PATH);
  };

  const { amountOfRemixes } = sharedImage;

  /*
  const emoji = () => {
    if (amountOfRemixes === 0) return "😢";
    if (amountOfRemixes < 5) return "😊";
    if (amountOfRemixes < 10) return "😎";
    if (amountOfRemixes < 20) return "🤩";
    if (amountOfRemixes < 50) return "🤯";
    if (amountOfRemixes < 100) return "🔥🤩";
    return "🔥🤯🤩😎";
  };
  */

  if (!sharedImage.image.imageUrl) return null;

  const imageToSave: FakeImage = {
    ...sharedImage.image,
    fromFeed: true,
  };
  return (
    <HistoryImageContainer>
      <Image
        src={sharedImage.image.imageUrl}
        loaded={loaded}
        onLoad={() => setLoaded(true)}
      />
      <AmountOfRemixesText>
        <BiUpvote size={18} /> {amountOfRemixes}
      </AmountOfRemixesText>
      <ButtonsBelowContainer>
        <RemixIconContainer onClick={handleGoToRemix}>
          <RemixIcon />
          Remix
        </RemixIconContainer>
        <DownloadIcon image={sharedImage.image} />
        <RemoveButton onClick={() => handleRemove(sharedImage)} />
        <CopyLinkToClipboardIcon image={sharedImage} />
        <SaveButtonContainer>
          <SaveButton image={imageToSave} sharedImageRefId={sharedImage.id} />
        </SaveButtonContainer>
      </ButtonsBelowContainer>
    </HistoryImageContainer>
  );
};

const AmountOfRemixesText = styled.div`
  font-size: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  margin-top: 8px;
`;
const Image = styled(SmoothlyLoadingImage)`
  width: 100%;
`;

const ButtonsBelowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const HistoryImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`;

const RemixIconContainer = styled(IconButtonContainer)`
  margin-right: 12px;
`;

const SaveButtonContainer = styled(IconButtonContainer)`
  margin-left: auto;
`;

export default SharedImageBig;
