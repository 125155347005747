import { useEffect, useState } from "react";
import styled from "styled-components";
import { useImagesContext } from "../../context/ImageContextProvider";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import TextFilter from "../../components/common/TextFilter";
import NonSharedImageFeed from "./NonSharedImageFeed";
import { useHandleFiltering } from "./useHandleFiltering";
import LoadingAnimation from "../../components/common/LoadingStuff/LoadingAnimation";
import EngineFilterDropdown from "../FeedPage/EngineFilterDropdown";
import { FakeImage, SavedImage } from "../../types";
import { Description } from "./SharedComponentsInLibrary";
import useGetSavedImagesWithToken from "../../hooks/userHandling/useGetSavedImagesWithToken";

// TODO refactor with history and saved images
const SavedImagesTab = () => {
  const { savedImages, setSavedImages, hasFetchedSavedImages } =
    useImagesContext();
  const { loggedInUser } = useLoggedInUserContext();
  const getSavedImagesWithToken = useGetSavedImagesWithToken();
  const [isLoadingFeed, setIsLoadingFeed] = useState<boolean>(false);

  useEffect(() => {
    const update = async () => {
      if (!loggedInUser || hasFetchedSavedImages === true) {
        return;
      }
      setIsLoadingFeed(true);
      const fetchedSavedImages: SavedImage[] = await getSavedImagesWithToken();
      fetchedSavedImages.length !== 0 && setSavedImages(fetchedSavedImages);
      setIsLoadingFeed(false);
    };
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const [engineFilter, setEngineFilter] = useState<string>("All");
  const handleFiltering = useHandleFiltering();

  const [filter, setFilter] = useState<string>("");
  const handleSetFilter = (filterInput: string) => {
    setFilter(filterInput);
    setIsLoadingFeed(true);
    setTimeout(() => {
      setIsLoadingFeed(false);
    }, 500);
  };

  const [itemsToShow, setItemsToShow] = useState<FakeImage[]>([]);
  useEffect(() => {
    const filteredImages = handleFiltering(savedImages, filter, engineFilter);

    setItemsToShow(filteredImages);
  }, [filter, engineFilter, savedImages]);

  if (!loggedInUser)
    return <TextContainer>Log in to save images</TextContainer>;

  return (
    <div>
      <TextContainer>
        <Description>
          These images are saved to your personal account. For free users, last
          500 are returned. For Gold users, last 3000
        </Description>
      </TextContainer>
      <EngineFilterDropdown
        value={engineFilter}
        setValue={setEngineFilter}
        inLibrary={true}
      />
      <TextFilter filter={filter} handleSetFilter={handleSetFilter} />
      {isLoadingFeed ? (
        <LoadingAnimation loading={isLoadingFeed} loaderToChoose={2} />
      ) : (
        <NonSharedImageFeed images={itemsToShow} />
      )}
    </div>
  );
};

const TextContainer = styled.div`
  margin-bottom: 18px;
  margin-top: 16px;
`;

export default SavedImagesTab;
