import { useNavigate } from "react-router-dom";
import "./TopBar.css";
import PicarusLogo from "./PicarusLogo";
import TopRightCornerPartOfTopBar from "./TopRightCornerPartOfTopBar";
import {
  TopBarContainer,
  LeftSideContainerInTopBar,
  RightSideContainerInTopBar,
} from "./TopBarCommonComponents";

const TopBar = () => {
  const navigate = useNavigate();

  const handleTakeToMainPage = () => {
    navigate("/");
  };

  /*
  const shouldUseVisible = window.location.href.includes("character");

  const { isVisible } = useHandleControlNavbar();
  */

  const showTheBar = true


  return (
    <TopBarContainer isVisible={showTheBar}>
      <LeftSideContainerInTopBar onClick={handleTakeToMainPage}>
        <PicarusLogo />
      </LeftSideContainerInTopBar>
      <RightSideContainerInTopBar>
        <TopRightCornerPartOfTopBar />
      </RightSideContainerInTopBar>
    </TopBarContainer>
  );
};

export default TopBar;
