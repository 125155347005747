import styled from 'styled-components';
import GeneratedImagesContainer from '../AdvancedPage/GeneratedImagesContainer';
import SaveButtonInGenerate from '../AdvancedPage/ButtonsBelowImage/SaveButtonInGenerate';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { sharedIconStyle } from '../../components/common/IconButtonStuff';
import { desktopMediaQuery } from '../../styleHelpers';
import { BORDER_COLOR } from '../../constants';

const GeneratedImagesMagicMode = () => {
  return (
    <Container>
      <GeneratedImagesContainer hideRandomTip />
      <ButtonsBelowMagicModeImage />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;

  ${desktopMediaQuery} {
    margin-left: 17vw;
  }
`;

const ButtonsBelowMagicModeImage = () => {
  const { activeImage } = useGeneralContext();

  const handleFullScreen = () => {
    if (activeImage) {
      window.open(activeImage.imageUrl, '_blank');
    }
  };

  return (
    <OuterContainerForButtons>
      <ContainerForButtons>
        <FullScreenIcon size={32} onClick={handleFullScreen} />
        <SaveButtonInGenerate image={activeImage} showSaveText={false} big />
      </ContainerForButtons>

      <HintText>
        You can hit "Create" to generate more images with the same options, or
        edit them below 👇
      </HintText>
    </OuterContainerForButtons>
  );
};

// TODO: fix this layout issue stuff
const OuterContainerForButtons = styled.div`
  display: flex;
  flex-direction: column;
  ${desktopMediaQuery} {
    margin-left: 6vw;
  }
`;

const ContainerForButtons = styled.div`
  display: flex;
  justify-content: space-evenly;

  margin-top: 32px;
  margin-left: 8px;

  ${desktopMediaQuery} {
    margin-left: -15vw;
  }
`;

const FullScreenIcon = styled(AiOutlineFullscreen)`
  ${sharedIconStyle}

  margin-bottom: -4px;
`;

const HintText = styled.div`
  border-top: 0.5px dotted ${BORDER_COLOR};
  font-size: 16px;
  margin-top: 40px;
  padding: 40px;
  padding-top: 48px;
  padding-bottom: 8px;

  ${desktopMediaQuery} {
    margin-left: -15vw;
  }
`;

export default GeneratedImagesMagicMode;
