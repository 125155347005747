import { useNavigate } from "react-router-dom";
import { isPreviewing, isLocalhost } from "../../App";
import {
  BASE_URL_FOR_FUNCTIONS,
  PREVIEW_URL_FOR_FUNCTIONS,
} from "../../constants";
import { useImagesContext } from "../../context/ImageContextProvider";
import { useLoggedInUserContext } from "../../context/LoggedInUserContextProvider";
import { FakeImage, SharedImage, EngineType } from "../../types";
import { v4 as uuidv4 } from "uuid";
import { useCookies } from "react-cookie";
import {
  checkCombinationsForBlocking,
  checkPromptForAllCelebs,
  checkPromptForBlocking,
} from "../../utils/clientSideBlocking";
import { Category } from "../../pages/FeedPage/EngineFilterDropdown";
import { PROFILE_PATH } from "../../pathNames";
import { toast } from "react-toastify";
import { trackPostedImage } from "../../utils/analytics";

interface Returns {
  status: number;
}
const headers = {
  "Content-type": "Application/json",
};

const allowedToShareEveryMs = 1000 * 60 * 5; // 5 minutes

const useShareImage = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const { sharedImages, setSharedImages, lastSharedDate, setLastSharedDate } =
    useImagesContext();
  const navigate = useNavigate();

  // TODO: this is actually "cookies" and inside is .token. The destructure is done in serverless function which is stupid.
  const [token] = useCookies(["token"]);

  const saveImage = async (image: FakeImage): Promise<Returns> => {
    if (!loggedInUser) {
      alert("You need to log in to share images");
      navigate(PROFILE_PATH);
      return { status: 401 };
    }

    if (!image.prompt) {
      window.alert("No image to share");
      return { status: 400 };
    }

    const isBadPrompt = checkPromptForBlocking(image.prompt);
    if (isBadPrompt) {
      alert(`You can't share this image`);
      return { status: 400 };
    }

    const isCelebPrompt = checkPromptForAllCelebs(image.prompt);
    if (isCelebPrompt) {
      alert(`You can't share this image`);
      return { status: 400 };
    }

    const shouldBlockForCombination = checkCombinationsForBlocking(
      image.prompt
    );
    if (shouldBlockForCombination) {
      alert(`You can't share this image`);
      return { status: 400 };
    }

    if (image.isInpainted) {
      alert("You can't share inpainted images, for now. Check back later");
      return { status: 400 };
    }

    if (image.selectedTags && image.selectedTags.length !== 0) {
      alert("You can't share images made with Magic Mode (for now). Check back later!");
      return { status: 400 };
    }

    const isShared = sharedImages.some(
      (sharedImage) => sharedImage.image.trackId === image.trackId
    );
    if (isShared) {
      alert("You already shared this image");
      return { status: 400 };
    }

    const isRemixing = !!image.remixing;
    if (isRemixing) {
      toast.error("You can't share a remix");
      return { status: 400 };
    }

    const isImg2Img = image.isImg2Img;
    if (isImg2Img) {
      toast.error("You can't share images you have upload edited");
      return { status: 400 };
    }

    const isFromFeed = !!image.fromFeed;
    if (isFromFeed) {
      toast.error("You can't share an image from the feed");
      return { status: 400 };
    }

    // compare dateTime now and lastSharedDate and see if it's been less than allowedToShareEveryMs
    const timeWhenAllowedToShare = new Date(
      lastSharedDate ? lastSharedDate.getTime() + allowedToShareEveryMs : 0
    );

    if (timeWhenAllowedToShare > new Date()) {
      alert(
        `You can only share an image every ${
          allowedToShareEveryMs / 1000 / 60
        } minutes`
      );
      return { status: 400 };
    }

    const ok = window.confirm(
      "Do you want to post this image to other users in Picarus"
    );
    if (!ok) return { status: 400 };

    trackPostedImage();

    // TODO: this needs to be done in a better way
    const getCategory = (engine: EngineType): Category => {
      if (engine.type === "digital") {
        return "Digital";
      }
      if (engine.type === "anime") {
        return "Anime";
      }
      if (engine.type === "realistic") {
        return "Realistic";
      }
      const { modelId } = engine;
      switch (modelId) {
        case "realistic-vision-v13":
        case "realistic-vision-14":
          return "Realistic";
        case "anything-v3":
        case "anything-v4":
        case "furry":
          return "Anime";
        case "perfect-world":
        case "chilloutmix":
          return "Digital";
        default:
          return "Realistic";
      }
    };

    const category = getCategory(image.engine);

    const userDetails = {
      username: loggedInUser.username,
      profileImageUrl: loggedInUser.profileImageUrl,
    };

    const imageToShare: SharedImage = {
      image: image,
      id: uuidv4(),
      title: "Untitled",
      user: userDetails,
      upvotes: 0,
      amountOfRemixes: 0,
      amountOfReports: 0,
      comments: [],
      sharedAt: new Date(),
      category,
      tags: [],
    };

    const newSharedImages = sharedImages.concat(imageToShare);
    setSharedImages(newSharedImages);

    toast.success(
      "Image shared! Wait a couple of minutes to see it on the feed."
    );

    // TODO: add a modal that opens and suggests to share the link

    setLastSharedDate(new Date());

    const functionName = "shareImage";
    let fetchUrl = isPreviewing
      ? `${PREVIEW_URL_FOR_FUNCTIONS}/${functionName}`
      : `${BASE_URL_FOR_FUNCTIONS}/${functionName}`;

    fetchUrl = isLocalhost ? `http://localhost:8080/${functionName}` : fetchUrl;

    const { status } = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({ imageToShare, token }),
      headers,
    });

    return { status };
  };
  return saveImage;
};

export default useShareImage;
