import { FaDiscord, FaReddit, FaTwitter } from 'react-icons/fa';
import styled from 'styled-components';
import { sharedIconStyle } from '../IconButtonStuff';
import { PRIMARY_COLOR } from '../../../constants';
interface I {
  bigger?: boolean;
}

export const DISCORD_URL = 'https://discord.gg/eCkrtmb79j';
export const DiscordLogo = ({ bigger }: I) => {
  const handleTakeToDiscord = () => {
    window.open(DISCORD_URL, '_blank');
  };

  if (bigger)
    return (
      <Container onClick={handleTakeToDiscord}>
        Join Discord <DiscordIcon />
      </Container>
    );
  return <DiscordIcon onClick={handleTakeToDiscord} />;
};

const DiscordIcon = styled(FaDiscord)`
  margin-right: 8px;
  ${sharedIconStyle}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: pointer;
    color: ${PRIMARY_COLOR};
  }
`;

export const REDDIT_URL = 'https://www.reddit.com/r/picarus_ai/';

export const RedditLogo = ({ bigger }: I) => {
  const handleTakeToReddit = () => {
    window.open('https://www.reddit.com/r/picarus_ai/', '_blank');
  };

  if (bigger)
    return (
      <Container onClick={handleTakeToReddit}>
        Visit subreddit <RedditIcon />
      </Container>
    );
  return <RedditIcon onClick={handleTakeToReddit} />;
};

const RedditIcon = styled(FaReddit)`
  ${sharedIconStyle}
`;

export const TwitterLogo = () => {
  const handleTakeToTwitter = () => {
    window.open('https://twitter.com/', '_blank');
  };

  return <TwitterIcon onClick={handleTakeToTwitter} />;
};

const TwitterIcon = styled(FaTwitter)`
  margin-left: 8px;
  ${sharedIconStyle}
`;
