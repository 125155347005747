import React from "react";
import { FolderInterface } from "../../../types";
import AddNewFolderButton from "./AddNewFolderButton";
import FolderListItem from "./FolderListItem";
import styled from "styled-components";

interface FoldersListProps {
  folders: FolderInterface[] | undefined;
  handleSaveToFolder: (folder: FolderInterface) => void;
  setShowAddNewFolderModal: (show: boolean) => void;
}

const FoldersList: React.FC<FoldersListProps> = ({
  folders,
  handleSaveToFolder,
  setShowAddNewFolderModal,
}) => {
  return (
    <ContainerForFolderList>
      <FolderList>
        {folders?.map((folder) => (
          <FolderListItem
            key={folder.id}
            folder={folder}
            handleSaveToFolder={() => handleSaveToFolder(folder)}
          />
        ))}
      </FolderList>
      <AddNewFolderButton
        handleClickAddNewFolder={() => setShowAddNewFolderModal(true)}
      />
    </ContainerForFolderList>
  );
};

const ContainerForFolderList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FolderList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; // Adjust to align items to the top
  overflow-y: auto;
  max-height: 400px;
  width: 100%; // Adjust width as needed
`;

export default FoldersList;
