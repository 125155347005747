import { useEffect, useState } from 'react';
import styled from 'styled-components';
import GenericDropdown from '../../components/common/GenericDropdown';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';
import { DESKTOP_WIDTH } from '../../constants';
import useGetFeed, { SortByType } from '../../hooks/feed/useGetFeed';
import { SharedImage } from '../../types';
import feedSettingsInStorage from '../../utils/feedSettingsInStorage';
import FeedContainer from './FeedContainer';
import TextFilter from '../../components/common/TextFilter';
import EngineFilterDropdown, {
  ENGINE_CATEGORIES,
} from './EngineFilterDropdown';
import { useLocation, useNavigate } from 'react-router-dom';

export const ENGINE_FILTER_LIST = ['All', 'Realistic', 'Anime', 'Digital'];

export const SORT_BY_LIST = [
  'Latest',
  'Best today',
  'Best past week',
  'Best past month',
  'Best all time',
];
export type SortSettings = (typeof SORT_BY_LIST)[number];

const FeedPage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const engineFromParams = params.get('engine');

  useEffect(() => {
    if (engineFromParams) {
      if (!ENGINE_CATEGORIES.includes(engineFromParams)) {
        handleSetSortBy('Best all time');
      }
      setCategoryFilter(engineFromParams);
      feedSettingsInStorage().category.set(engineFromParams);
    }
  }, [engineFromParams]);

  const [images, setImages] = useState<SharedImage[]>([]);
  const [categoryFilter, setCategoryFilter] = useState('All');

  const [sortBy, setSortBy] = useState('Best past month');
  const { getLatestShared, getBestShared } = useGetFeed();

  const [isLoadingFeed, setIsLoadingFeed] = useState(false);

  const from = 0;
  const to = 350;

  useEffect(() => {
    setIsLoadingFeed(true);
    const categorySettingInLocalStorage =
      feedSettingsInStorage().category.get();
    categorySettingInLocalStorage &&
      setCategoryFilter(categorySettingInLocalStorage);

    // this hack is needed so useGetFeed knows it's an engine and not a category
    const isEngFilter = !ENGINE_CATEGORIES.includes(
      categorySettingInLocalStorage,
    );

    const sortBySettingInLocalStorage = feedSettingsInStorage().sortBy.get();
    sortBySettingInLocalStorage && setSortBy(sortBySettingInLocalStorage);

    const getLatest = sortBySettingInLocalStorage === 'Latest';
    getLatest &&
      !isLoadingFeed &&
      getLatestShared(from, to, categorySettingInLocalStorage).then((data) => {
        data && setImages(data);
        setIsLoadingFeed(false);
      });

    const getBest = !getLatest;
    getBest &&
      !isLoadingFeed &&
      getBestShared(
        from,
        to,
        categorySettingInLocalStorage,
        sortBySettingInLocalStorage as SortByType,
        isEngFilter,
      ).then((data) => {
        data && setImages(data);
        setIsLoadingFeed(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter, sortBy]);

  const handleSetEngineFilter = (category: string) => {
    if (!ENGINE_CATEGORIES.includes(category)) {
      handleSetSortBy('Best all time');
    }
    // sets the url params
    params.set('engine', category);
    navigate(`${location.pathname}?${params.toString()}`);
    setCategoryFilter(category);
    feedSettingsInStorage().category.set(category);
  };

  const supportedSortBy = ['Best all time', 'Latest'];

  const handleSetSortBy = (sortBy: string) => {
    // if sortBy is either Best all time or Latest, then it's ok
    if (
      !ENGINE_FILTER_LIST.includes(categoryFilter) &&
      !supportedSortBy.includes(sortBy)
    ) {
      alert(
        "Currently, engine filters support only 'Best All Time' and 'Latest'",
      );
      return;
    }
    setSortBy(sortBy);
    feedSettingsInStorage().sortBy.set(sortBy);
  };

  const [filter, setFilter] = useState<string>('');
  const handleSetFilter = (filterInput: string) => {
    setFilter(filterInput);
    setIsLoadingFeed(true);
    setTimeout(() => {
      setIsLoadingFeed(false);
    }, 1000);
  };

  const handleFiltering = (
    images: SharedImage[],
    searchTerm: string,
  ): SharedImage[] => {
    if (searchTerm === '') {
      return images;
    }
    const searchWords = searchTerm.toLowerCase().split(/\s|,|\(|\)|:/);

    const filteredImages = images.filter((image) => {
      const prompt = image.image.prompt.replace(/\(|\)|,|:|\./g, ' ');
      const promptWords = prompt.toLowerCase().split(' ');

      return searchWords.every((word) => promptWords.includes(word));
    });

    return filteredImages;
  };

  const filteredImages = handleFiltering(images, filter);

  const isOffline = false;

  return isOffline ? (
    <div>Under construction 🚧</div>
  ) : (
    <Container>
      <FeedtopBarContainer>
        <DropdownContainer>
          <EngineFilterDropdown
            value={categoryFilter}
            setValue={handleSetEngineFilter}
          />
          <GenericDropdown
            value={sortBy}
            setValue={handleSetSortBy}
            selectableValues={SORT_BY_LIST}
            label="Sort by"
          />
        </DropdownContainer>
        <TextFilter filter={filter} handleSetFilter={handleSetFilter} />
      </FeedtopBarContainer>
      {isLoadingFeed ? (
        <LoadingAnimation loading={isLoadingFeed} loaderToChoose={2} />
      ) : (
        <FeedContainer images={filteredImages} />
      )}
    </Container>
  );
};

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    margin-left: 15vw;
    max-width: 80vw;
  }
`;

const FeedtopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export default FeedPage;
