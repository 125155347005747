import { BiUpvote } from "react-icons/bi";
import styled from "styled-components";
import { SharedImage } from "../../types";
import { useState } from "react";
import { PLACEHOLDER_IMAGE_PATH } from "../../components/ImageStuff/GeneratedImage";
import { SmoothlyLoadingImage } from "../../components/common/SmoothlyLoadingImage";
import { imageHoverAnimationInFeed } from "../../styleHelpers";

interface ImageTemplateNewProps {
  image: SharedImage;
}

const FeedImage = ({ image }: ImageTemplateNewProps) => {
  const imageUrl = image?.image?.imageUrl;
  const [loaded, setLoaded] = useState(false);

  if (!imageUrl) return null;
  if (image.reported) return null;

  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <Image
        src={imageUrl || PLACEHOLDER_IMAGE_PATH}
        alt="A feed image"
        loading="lazy"
        onLoad={() => setLoaded(true)}
        loaded={loaded}
      />
      <AmountOfRemixesBadge>
        <BiUpvote size={12} />
        {image.amountOfRemixes}
      </AmountOfRemixesBadge>
    </Container>
  );
};

export default FeedImage;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 240px;
  max-height: 300px;
`;

const AmountOfRemixesBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 8px;
  font-size: 12px;
  border-radius: 0 10px 0 0;
  cursor: default;
`;

const Image = styled(SmoothlyLoadingImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${imageHoverAnimationInFeed}
`;
