import { getBaseUrlForFetching } from '../App';
import { waitSeconds } from '../components/helpers';
import { useGeneralContext } from '../context/GeneralContextProvider';
import { useImagesContext } from '../context/ImageContextProvider';
import { useImageGenerationSettingsContext } from '../context/ImageGenerationSettingsProvider';
import { useLoggedInUserContext } from '../context/LoggedInUserContextProvider';
import { FakeImage } from '../types';
import {
  trackFinishedFreeUserSlowGeneration,
  trackFinishedImageGeneration,
  trackFinishedImageGenerationNewAPI,
  trackFinishedNonLoggedInSlowGeneration,
  trackFirstTimeUserFinishesImageGeneration,
  trackGoldUserFinishesGeneration,
  trackImageGenerationFinished,
} from '../utils/analytics';
import { headers } from './helpers';

const WAIT_TIME = 3;

const AMOUNT_OF_RETRIES = 20;

const useGetImageDataWithTrackId = () => {
  const { cancelled, setCancelled, generationReadyTimestamp } =
    useGeneralContext();

  const { loggedInUser } = useLoggedInUserContext();

  const { speedMode } = useImageGenerationSettingsContext();

  const { failedTrackIds, setFailedTrackIds } = useImagesContext();

  const getImageDataFromTrackId = async (
    trackId: string,
  ): Promise<FakeImage | null | 'cancelled'> => {
    let retries = 0;

    const functionName = 'getImageData';

    const baseUrl = getBaseUrlForFetching();

    const shouldStartFetching = () => {
      let msToMinusFromTimestamp = 4000;
      const now = Date.now();
      return now >= generationReadyTimestamp - msToMinusFromTimestamp;
    };

    while (true) {
      if (failedTrackIds.includes(trackId)) {
        return 'cancelled';
      }
      if (cancelled) {
        setCancelled(false);
        return 'cancelled';
      }
      if (!shouldStartFetching()) {
        await waitSeconds(WAIT_TIME);
        continue;
      }

      const fetchUrl = `${baseUrl}/${functionName}`;
      try {
        const response = await fetch(fetchUrl, {
          method: 'POST',
          body: JSON.stringify({ trackId }),
          headers,
        });

        // to omany requests
        if (response.status === 429) {
          await waitSeconds(WAIT_TIME);
          continue;
        }

        if (response.status === 200) {
          const data = (await response.json()) as FakeImage;
          // if url is "generating", wait a bit and try again
          if (data.imageUrl === 'generating') {
            console.log("data.imageUrl === 'generating'")
            retries++;

            if (retries > AMOUNT_OF_RETRIES) {
              console.log("Setting failed trackids after generating")
              setFailedTrackIds((s) => [...s, trackId]);
              return null;
            }

            await waitSeconds(WAIT_TIME);
            continue;
          }
          const isLoggedIn = !!loggedInUser;
          // REDO THIS ALL
          if (!isLoggedIn && speedMode === 'normal') {
            trackFinishedNonLoggedInSlowGeneration();
          } else if (!isLoggedIn && speedMode === 'turbo') {
            trackFirstTimeUserFinishesImageGeneration();
          } else if (loggedInUser?.type === 'gold') {
            trackGoldUserFinishesGeneration();
          } else if (speedMode === 'normal') {
            trackFinishedFreeUserSlowGeneration();
          } else if (speedMode === 'fast') {
            trackFinishedImageGeneration('fast');
          } else if (speedMode === 'turbo') {
            trackFinishedImageGeneration('turbo');
          }

          trackImageGenerationFinished();

          if (!!data?.newAPIUsed) {
            trackFinishedImageGenerationNewAPI();
          }
          return data;
        }

        if (response.status === 404) {
          await waitSeconds(WAIT_TIME);
          console.log("response status 404")
          if (retries > AMOUNT_OF_RETRIES) {
            console.log("setting failed trackids")
            setFailedTrackIds((s) => [...s, trackId]); 
            return 'cancelled';
          }
          continue;
        }
      } catch (error) {
        retries++;
        if (retries > AMOUNT_OF_RETRIES) {
          return null;
        }
        await waitSeconds(WAIT_TIME);
        continue;
      }
    }
  };
  return getImageDataFromTrackId;
};

export default useGetImageDataWithTrackId;
