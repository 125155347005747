import { useEffect, useState } from "react";
import styled from "styled-components";

const getTips = (): string[] => [
  "Try clearing your browser cache if you have problems to update to the latest version",
  "Check the Feed for inspiration",
  "If the image is very deformed, remove (((too many paranthesis))) or (too high number:1.6)",
  "Start simple and add detail with each prompt",
  "AI pays more attention to first keywords in the prompt",
  "AI pays more attention to (keywords in parentheses)",
  "Remix community images from the Feed",
  "CFG tells the AI how much prompt instructions are followed. Usually not necessary to change",
  "Same seed makes more consistent images; freeze seed when making small tweaks",
  "AI avoids rendering parts in negative prompt. Use it to your advantage!",
  "Check your history and saved images in library",
  "Often best results require multiple tries without changing the prompt",
  "Check advanced options for more control",
  "Use the 'remix' button to make a new image from an existing one",
  "(Brunette:1.2) means the AI pays 20% MORE attention to the word 'Brunette'",
  "(Brunette:0.8) means the AI pays 20% LESS attention to the word 'Brunette'",
  "Avoid more than (( )) two parantheses or going higher than ( :1.4)",
  "Freezing the seed means only small changes will be made to your AI generation",
  "Adding (detailed face and eyes:1.2) often fixes a lot of face-related problems",
  "While browsing the feed on computer, use arrow keys to move",
];

const RandomTip = () => {
  const getRandomTip = () => {
    const tips = getTips();
    const randomIndex = Math.floor(Math.random() * tips.length);
    return tips[randomIndex];
  };
  const [tip, setTip] = useState(getRandomTip());

  useEffect(() => {
    const interval = setInterval(() => {
      const randomTip = getRandomTip();
      setTip(randomTip);
    }, 9000);
    return () => clearInterval(interval);
  }, []);

  return <TipText>Tip: {tip}</TipText>;
};

const TipText = styled.div`
  position: absolute;
  bottom: 140px;
  left: -90px;
  width: 300px;
  font-size: 14px;
  font-style: italic;
`;

export default RandomTip;
