import styled from 'styled-components';
import GeneratedImage from '../../components/ImageStuff/GeneratedImage';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { useEffect, useState } from 'react';
import { desktopStyleToAdd } from '../../styleHelpers';
import { fadeIn } from '../../components/ImageStuff/animations';

const GeneratedImagesContainer = ({
  hideRandomTip,
}: {
  hideRandomTip?: boolean;
}) => {
  const { images, activeImage, setActiveImage } = useGeneralContext();
  const [selectedItem, setSelectedItem] = useState(images.length - 1);

  const activeImageHeight = activeImage?.height;

  const [carouselHeight, setCarouselHeight] = useState(
    activeImageHeight ?? 512,
  );

  useEffect(() => {
    if (activeImageHeight) {
      setCarouselHeight(activeImageHeight);
    }
  }, [activeImage, activeImageHeight]);

  function handleChange(selectedIndex: number) {
    const newActiveIndex = selectedIndex;
    setSelectedItem(newActiveIndex);

    const newActiveImage = images[newActiveIndex];

    if (!newActiveImage) {
      return;
    }

    setActiveImage(newActiveImage);

    const activeImageHeight = activeImage?.height;

    setCarouselHeight(activeImageHeight ?? 512);
  }

  useEffect(() => {
    if (images.length === 0) {
      return;
    }

    setSelectedItem(images.length - 1);
  }, [images]);

  return (
    <ImageCarousel
      height={carouselHeight}
      showIndicators={images.length < 5}
      showThumbs={false}
      showStatus={false}
      selectedItem={selectedItem}
      emulateTouch
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
      onChange={handleChange}
      useKeyboardArrows={true}
    >
      {images.map((image) => (
        <GeneratedImage
          image={image}
          key={image.trackId}
          hideRandomTip={hideRandomTip}
        />
      ))}
    </ImageCarousel>
  );
};

const ImageCarousel = styled(Carousel)<{ height: number }>`
  width: 100%;
  max-height: ${(props) => props.height}px;
  padding-left: 10px;
  margin-bottom: 8px;
  ${desktopStyleToAdd}

  // this doesnt work as of now for some reason
  display: flex;
  justify-content: center;

  // hack so when going up looks better
  animation: ${fadeIn} 3s ease-in-out;
`;

export default GeneratedImagesContainer;
