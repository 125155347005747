import styled, { keyframes } from 'styled-components';
import { GenericSetState, SharedImage } from '../../types';
import { AiOutlineInfoCircle, AiOutlineSave } from 'react-icons/ai';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { MdOutlineReport } from 'react-icons/md';
import { CloseButtonInFeedModal } from '../../components/common/CloseButtonInFeedModal';
import SaveButton from '../../components/common/SaveButton';
import { DESKTOP_WIDTH, MAX_WIDTH } from '../../constants';
import { useHandleDownloadImage } from '../../hooks/images/useHandleDownloadImage';
import useReportImage from '../../hooks/feed/useReportImage';
import { useNavigate } from 'react-router-dom';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import useUpdateAmountOfRemixes from '../../hooks/feed/useUpdateAmountOfRemixes';
import { useEffect, useState } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import InfoModal from '../../components/Modals/InfoModal';
import { IconButton } from '../../components/common/IconButtonInFeed';
import { RemixButton } from '../../components/common/RemixButton';
import { HOME_PATH } from '../../pathNames';
import { desktopMediaQuery } from '../../styleHelpers';
import { ModalThatTransitionsFromBottom } from '../../components/ImageStuff/animations';
import { ModalContent } from '../../components/common/sharedComponentsInFeeds';
import CopyLinkToClipboardIcon from '../../components/common/CopyLinkToClipboardIcon';
import { toast } from 'react-toastify';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
  image: SharedImage | null;
  handleShowPreviousImage: () => void;
  handleShowNextImage: () => void;

  handleSaveIsClicked?: () => void;
}

export interface InfoText {
  prompt: string;
  negativePrompt: string;
  engine: string;
  style?: string;
  seed: number;
  guidanceScale: number;
}

const FeedModal = ({
  showModal,
  setShowModal,
  image,
  handleShowPreviousImage,
  handleShowNextImage,

  handleSaveIsClicked,
}: I) => {
  const reportImage = useReportImage();
  const handleDownloadImage = useHandleDownloadImage();
  const navigate = useNavigate();

  const { setActiveImage, setImages } = useGeneralContext();
  const updateAmountOfRemixes = useUpdateAmountOfRemixes();

  const handleClose = () => {
    setShowModal(false);
    window.history.replaceState(null, '', `/app/feed`);
  };

  // TODO: remove this at some point!!
  // Add a random 10% chance that the image will be rewarded an updateAmountOfRemixes
  useEffect(() => {
    const random = Math.random();
    if (random < 0.1) {
      image && updateAmountOfRemixes(image);
    }
  }, [image]);

  const handleRemixingImage = () => {
    if (!image) return;
    const fakeImage = image.image;
    fakeImage.remixing = true;
    if (image.image.parentImageTrackId) {
      fakeImage.parentImageTrackId = image.image.parentImageTrackId;
    } else {
      fakeImage.parentImageTrackId = image.image.trackId;
    }
    setActiveImage(fakeImage);
    setImages((images) => images.concat(fakeImage));

    // TODO: like the image as well here
    // TODO: change "saves" into likes
    // TODO: maybe let users have a lot more "likes" in their library...
    updateAmountOfRemixes(image);

    navigate(HOME_PATH);

    window.scrollTo(0, 0);
  };

  const handleDownload = () => {
    image && handleDownloadImage(image.image);
  };

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalText, setInfoModalText] = useState<InfoText | undefined>(undefined);
  const showImageInfoModal = (info: InfoText) => {
    if (image?.image.selectedTags && image.image.selectedTags.length > 0) {
      toast.error('Info not available for Magic Mode images, check back later');
      return;
    }
    setInfoModalText(info);
    setShowInfoModal(true);
  };

  const handleShowImageInfo = () => {
    if (!image) return;
    const { prompt, negativePrompt, engine, guidanceScale, seed, style } =
      image.image;
    const info = {
      prompt,
      negativePrompt,
      engine: engine.name,
      style: style?.name,
      seed,
      guidanceScale,
    };

    showImageInfoModal(info);
  };

  const handleReportImage = () => {
    const ok = window.confirm('Are you sure you want to report this image?');
    if (!ok) return;
    if (!image) return;
    reportImage(image);

    alert(
      'Image reported, thank you! If the report is valid, the image will be removed and maker banned',
    );
  };

  const [positionY, setPositionY] = useState(1);
  const [positionX, setPositionX] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState<
    'horizontal' | 'vertical' | null
  >(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleShowNextImage(),
    onSwipedRight: () => handleShowPreviousImage(),
    onSwipedDown: () => handleClose(),
    trackMouse: true,
    onSwiping: (eventData: SwipeEventData) => {
      eventData.event.preventDefault();

      if (!swipeDirection) {
        setSwipeDirection(
          Math.abs(eventData.deltaX) > Math.abs(eventData.deltaY)
            ? 'horizontal'
            : 'vertical',
        );
      }

      setPositionX((prev) => prev + eventData.deltaX * 0.05);

      if (swipeDirection === 'horizontal') {
        setPositionX((prev) => prev + eventData.deltaX * 0.2);
      }

      if (swipeDirection === 'vertical') {
        setPositionY((prev) => Math.max(prev + eventData.deltaY * 0.3, 0));
      }
    },
    onSwiped: () => resetAll(),
    // change delta to make swipe shorter or longer
    delta: 10,
  });

  const resetAll = () => {
    setPositionX(0);
    setPositionY(1);
    setSwipeDirection(null);
  };

  //always when active image changes, reset positions
  useEffect(() => {
    resetAll();
  }, [image]);

  useEffect(() => {
    const handleKeyUp = (e: any) => {
      if (e.key === 'ArrowLeft') {
        handleShowPreviousImage();
      }
      if (e.key === 'ArrowRight') {
        handleShowNextImage();
      }
      if (e.key === 'Escape' || e.key === 'ArrowDown') {
        handleClose();
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleShowNextImage, handleShowPreviousImage]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showModal]);

  if (!image) return null;

  const isSmallHeight = window.innerHeight < 800;

  const isOnMobile = window.innerWidth < DESKTOP_WIDTH - 210;

  return (
    <ModalThatTransitionsFromBottom
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        },
        content: {
          display: 'flex',
          flexDirection: 'column',

          marginTop: isSmallHeight ? '5vh' : '8vh',
          left: '0',
          right: '0',
          bottom: '0',
          background: 'transparent',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '0',
          outline: 'none',
          padding: '0',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ModalContent
        {...handlers}
        style={{
          transform: `translate(${positionX}px, ${positionY}px)`,
        }}
      >
        <InfoModal
          showModal={showInfoModal}
          setShowModal={setShowInfoModal}
          infoText={infoModalText}
        />

        <CloseButtonInFeedModal onClick={handleClose} />

        <StyledImage key={image.image.imageUrl} src={image.image.imageUrl} />
      </ModalContent>

      <BottomButtonsContainer isOnMobile={isOnMobile}>
        <CopyLinkToClipboardIcon image={image} inFeedModal />

        <IconContainer onClick={handleReportImage}>
          <ReportButtonOnZoom />
        </IconContainer>

        <IconContainer onClick={handleDownload}>
          <DownloadButton />
        </IconContainer>

        <IconContainer onClick={handleRemixingImage}>
          <RemixButton />
        </IconContainer>

        <IconContainer onClick={handleShowImageInfo}>
          <InfoButton />
        </IconContainer>

        <IconContainer>
          <IconButton>
            <SaveButton
              image={image.image}
              handleSaveIsClicked={handleSaveIsClicked}
              smaller
              inModal
              sharedImageRefId={image.id}
            />
          </IconButton>
        </IconContainer>
      </BottomButtonsContainer>

      {!isOnMobile && (
        <ForwardBackButtonContainer>
          <GoToPreviousImageButton onClick={handleShowPreviousImage} />
          <GoToNextImageButton onClick={handleShowNextImage} />
        </ForwardBackButtonContainer>
      )}
    </ModalThatTransitionsFromBottom>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

// TODO: make both feeds into one component!!
const dekstopMaxWidth = 700;

const StyledImage = styled.img`
  object-fit: contain;
  max-width: ${MAX_WIDTH}px;
  width: 95%;
  height: auto;
  align-self: center;
  max-height: 80vh;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    margin-top: 8px;
    max-width: ${dekstopMaxWidth}px;
    max-height: ${dekstopMaxWidth}px;
  }

  animation: ${fadeIn} 0.2s ease-in;
`;

const BottomButtonsContainer = styled.div<{
  isOnMobile: boolean;
}>`
  z-index: 110;
  position: absolute;
  // 8vh if not on mobile, 2 if on mobile
  bottom: ${({ isOnMobile }) => (isOnMobile ? '2vh' : '8vh')};
  @media (min-width: ${DESKTOP_WIDTH}px) {
    bottom: 60px;
  }
  right: calc((100vw - ${MAX_WIDTH}px) / 2 + 60px);
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const IconContainer = styled.div`
  padding: 4px;
`;

interface ButtonProps {
  onClick: () => void;
}

export const iconSize = 26;

const InfoButton = () => (
  <IconButton>
    <AiOutlineInfoCircle size={iconSize} />
  </IconButton>
);

const DownloadButton = () => (
  <IconButton>
    <AiOutlineSave size={iconSize * 1} />
  </IconButton>
);

const ReportButtonOnZoom = () => (
  <IconButton>
    <MdOutlineReport color={'red'} size={iconSize * 1.1} />
  </IconButton>
);

const ForwardBackButtonContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

const BottomButton = styled.div`
  color: white;
  ${desktopMediaQuery} {
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }
  }
  font-size: 40px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5%;
`;

const GoToPreviousImageButton = ({ onClick }: ButtonProps) => (
  <BottomButton onClick={onClick}>
    <IoMdArrowBack />
  </BottomButton>
);

const GoToNextImageButton = ({ onClick }: ButtonProps) => (
  <BottomButton onClick={onClick}>
    <IoMdArrowForward />
  </BottomButton>
);

export default FeedModal;
