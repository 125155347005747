import { useState } from "react";
import { MdSearch } from "react-icons/md";
import styled from "styled-components";
import { InputContainer } from "./InputContainer";
import StylishInput from "./StyledInput";

interface P {
  filter: string;
  handleSetFilter: (filter: string) => void;
}

const TextFilter: React.FC<P> = ({ filter, handleSetFilter }) => {
  const [value, setValue] = useState(filter);
  const setFilter = () => {
    handleSetFilter(value);
  };
  return (
    <Container
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          setFilter();
        }
      }}
    >
      <InputContainer withIcon>
        <StylishInput
          value={value}
          setValue={setValue}
          placeholder="Search prompts on the list"
          withIcon
        />
        <StyledFilterButton onClick={setFilter} size={30}>
          Filter
        </StyledFilterButton>
      </InputContainer>
    </Container>
  );
};

export default TextFilter;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 6px;
`;

const StyledFilterButton = styled(MdSearch)`
  cursor: pointer;
  z-index: 2;
`;
